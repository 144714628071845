import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateProfile } from "../../store/auth/actions";
import store from "../../store";

const EditProfile = () => {
  const user = useSelector((state) => state.auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = { name, username, password };
    store.dispatch(updateProfile(data));
  }

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setUsername(user.username);
  }, [user]);

  return <>
    <div className={"col-12"}>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Edit profile</h5>
        </div>
        <div className="card-body">

          <form onSubmit={handleSubmit}>
            <div className="row form-group">
              <label htmlFor="username" className="col-sm-3 col-form-label input-label">Username</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="username" value={username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} required={true} />
              </div>
            </div>
            <div className="row form-group">
              <label htmlFor="name" className="col-sm-3 col-form-label input-label">Name</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="name" placeholder="Your Name"
                  value={name} onChange={(e) => setName(e.target.value)} required={true} />
              </div>
            </div>
            <div className="row form-group">
              <label htmlFor="email" className="col-sm-3 col-form-label input-label">Email</label>
              <div className="col-sm-9">
                <input type="email" className="form-control" id="email" placeholder="Email" disabled={true}
                  value={email} onChange={(e) => setEmail(e.target.value)} readOnly={true} />
              </div>
            </div>
            <div className="row form-group">
              <label htmlFor="password" className="col-sm-3 col-form-label input-label">Change Password</label>
              <div className="col-sm-9">
                <input type="password" className="form-control" id="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </>;
}

export default EditProfile;
