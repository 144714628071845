import axios from "axios";
import baseConfig from '../Config';
import toast from "react-hot-toast";


export const getAllStores = async () => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        let stores = [];
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/store/all/stores?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data.length !== 0) {
                    stores.push(...res.data);
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                page = 0;
            }
        }
        return stores;

    }
}

export const addOutlet = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/outlet`,
                body,
                config
            );
            toast.success("Outlet added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}


export const updateOutlet = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/outlet/${id}`,
                body,
                config
            );
            toast.success("Outlet updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getOutlet = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/outlet/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getAllOutlets = async (store) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        let outlets = [];
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/outlet/all/outlets?page=${page}&limit=${limit}&store=${store}`,
                    config
                );
                if (res.data.length !== 0) {
                    outlets.push(...res.data);
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                page = 0;
            }
        }
        return outlets;

    }
}
