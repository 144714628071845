import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import { useSelector } from "react-redux";
import {addBlog, getBlog, updateBlog} from "../../services/BlogService";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { getAllStores } from "../../services/OutletService";
import { upload } from "../../store/utility/action";
import {useParams} from "react-router";
import {getCoupon} from "../../services/CouponService";



const EditBlog = () => {
    const params = useParams();
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [title, setTitle] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [content, setContent] = useState("");
    const [store, setStore] = useState({ label: "", value: null });
    const [category, setCategory] = useState({ label: "", value: null });
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [storeOptions, setStoreOptions] = useState([]);

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const s of _loadedStores) {
            _stores.push({ label: s.name, value: s._id });
        }
        setStoreOptions(_stores);
    }


    useEffect(() => {
        loadAllStores();
    }, []);


    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({ label: cat.title, value: cat._id });
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let _imageUrl = imageUrl;
        let _videoUrl = videoUrl;
        if (image !== null) {
            _imageUrl = await uploadFile(image);
        }
        if (video !== null) {
            _videoUrl = await uploadFile(video);
        }
        const data = {
            title,
            content,
            shortDescription,
            slug,
            ...(store.value !== null && { store: store.value }),
            ...(category.value !== null && { category: category.value }),
            image: _imageUrl,
            video: _videoUrl,
        };
        await updateBlog(params.blog, data);
        setLoading(false);

    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadDetails = async () => {
        const blog = await getBlog(params.blog);
        console.log(blog);
        if (blog) {
            setTitle(blog.title);
            setContent(blog.content);
            setShortDescription(blog.shortDescription);
            setSlug(blog.slug);
            setImageUrl(blog.image);
            setVideoUrl(blog.video);
            if (blog.category) {
                setCategory({label: blog.category.title, value: blog.category._id});
            }
            if (blog.store) {
                setStore({label: blog.store.name, value: blog.store._id});
            }
        }
    }

    useEffect(() => {
        loadDetails();
    }, [params.blog]);


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Edit Blog</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Title" value={title}
                                           onChange={(e) => setTitle(e.target.value)} required={true} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Short Description <span
                                        className='text-danger'>(required)</span></label>
                                    <textarea className="form-control" onChange={(e) => setShortDescription(e.target.value)} value={shortDescription} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="slug" className="form-label">Slug <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="slug" name="slug" placeholder="Slug" value={slug}
                                           onChange={(e) => setSlug(e.target.value)} required={true} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="store" className="form-label">Category</label>
                                    <Select
                                        name="form-field-name"
                                        value={category}
                                        options={categoryOptions}
                                        onChange={setCategory}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Image</label>
                                    <input type="file" className="form-control" accept="image/*" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                                    {imageUrl !== null && <a href={imageUrl}>Click here to download image</a>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="video" className="form-label">Video</label>
                                    <input type="file" className="form-control" accept="video/*" id="video" name="video" onChange={(e) => setVideo(e.target.files[0])} />
                                    {videoUrl !== null &&  <a href={videoUrl}>Click here to download video</a>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="store" className="form-label">Store</label>
                                    <Select
                                        name="form-field-name"
                                        value={store}
                                        options={storeOptions}
                                        onChange={setStore}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Content <span
                                className='text-danger'>(required)</span></label>
                            <ReactQuill className="mb-3" theme="snow" value={content} onChange={setContent} required={true} />
                        </div>
                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status" />} Submit</button>

                    </form>

                </div>
            </div>
        </>);
}

export default EditBlog;


