import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addMember = async (data) => {
  let token = localStorage.getItem("token");
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const body = JSON.stringify(data);
    try {
      await axios.post(`${baseConfig.apiBaseUrl}/team`, body, config);
      toast.success("Team member added successfully", {});
      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {});
        return false;
      }
    }
  }
};

export const generateRandomPassword = (length) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
};

export const updateTeamMember = async (id, data) => {
  let token = localStorage.getItem("token");
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const body = JSON.stringify(data);
    try {
      await axios.patch(`${baseConfig.apiBaseUrl}/team/${id}`, body, config);
      toast.success("Admin updated successfully", {});
      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {});
      }
      return false;
    }
  }
};

export const getTeamMemberDetails = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/team/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}