import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addItem = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/item`,
                body,
                config
            );
            toast.success("Item added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}


export const updateItem = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/item/${id}`,
                body,
                config
            );
            toast.success("Item updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getItem = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/item/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}
