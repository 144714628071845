import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getAllOutlets} from "../../services/OutletService";
import {addDeal, getDeal, updateDeal} from "../../services/DealService";
import Select from "react-select";
import {useParams} from "react-router";
import {getItem} from "../../services/ItemService";
import {upload} from "../../store/utility/action";

const EditDeal = () => {
    const params = useParams();
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [outletOptions, setOutletOptions] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [shortDescription, setShortDescription] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);
    const [timings, setTimings] = useState({
        monday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        tuesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        wednesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        thursday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        friday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        saturday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        sunday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
    });

    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])

    const loadAllOutlets = async (store) => {
        let _loadedOutlets = await getAllOutlets(store);
        let _outlets = [];
        for (const outlet of _loadedOutlets) {
            _outlets.push({
                label: `${outlet.state != null ? outlet.state.title : ''}/${outlet.city != null ? outlet.city.title : ''}/${outlet.locality != null ? outlet.locality.title : ''}`,
                value: outlet._id
            });
        }
        setOutletOptions(_outlets);
    }

    const loadDetails = async () => {
        const deal = await getDeal(params.deal);
        let _tags = [];
        let _outlets = [];
        if (deal) {
            loadAllOutlets(deal.store);
            setTitle(deal.title);
            setShortDescription(deal.shortDescription);
            setOriginalPrice(deal.originalPrice);
            setDiscountedPrice(deal.discountedPrice);
            setTimings(deal.timings);
            setImageUrl(deal.image);
            setQuantity(deal.quantity ? deal.quantity : 0);
            if (deal.expiryDate) {
                setExpiryDate(deal.expiryDate.split('T')[0]);
            }
            if (deal.category) {
                setCategory({label: deal.category.title, value: deal.category._id});
            }
            for (const tag of deal.tags) {
                _tags.push({label: tag.title, value: tag._id})
            }
            setTags(_tags);
            for (const outlet of deal.outlets) {
                _outlets.push({label: `${outlet.state != null ? outlet.state.title : ''}/${outlet.city != null ? outlet.city.title : ''}/${outlet.locality != null ? outlet.locality.title : ''}`, value: outlet._id});
            }
            setOutlets(_outlets);
        }
    }

    useEffect(() => {
        loadDetails();
    }, [params.deal]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage(image);
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const _outlets = [];
        for (const o of outlets) {
            _outlets.push(o.value);
        }
        const data = {
            title,
            shortDescription,
            originalPrice,
            discountedPrice,
            expiryDate,
            timings,
            outlets: _outlets,
            category: category.value,
            tags: _tags,
            ...(imageUrl !== '' && {image: imageUrl,}),
            quantity,
        };
        const res = await updateDeal(params.deal, data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Edit Deal</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title"
                                       placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Quantity <span
                                    className='text-danger'>(required)</span></label>
                                <input type="number" className="form-control" id="quantity" name="quantity"
                                       placeholder="Quantity" value={quantity}
                                       onChange={(e) => setQuantity(parseInt(e.target.value))} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="expiry-date" className="form-label">Expiry Date</label>
                                <input type="date" className="form-control" id="expiry-date" name="expiry-date"
                                       placeholder="Expiry Date" value={expiryDate}
                                       onChange={(e) => setExpiryDate(e.target.value)}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="short-description" className="form-label">Short Description</label>
                            <textarea className="form-control" id="short-description" rows="2"
                                      value={shortDescription}
                                      onChange={(e) => setShortDescription(e.target.value)}/>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="original-price" className="form-label">Original Price <span
                                    className='text-danger'>(required)</span></label>
                                <input type="number" className="form-control" id="original-price"
                                       name="original-price"
                                       placeholder="Original Price" value={originalPrice}
                                       onChange={(e) => setOriginalPrice(parseFloat(e.target.value))}
                                       required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="discounted-price" className="form-label">Discounted Price <span
                                    className='text-danger'>(required)</span></label>
                                <input type="number" className="form-control" id="discounted-price"
                                       name="discounted-price"
                                       placeholder="Discounted Price" value={discountedPrice}
                                       onChange={(e) => setDiscountedPrice(parseFloat(e.target.value))}
                                       required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Category</label>
                            <Select
                                name="category"
                                options={categoryOptions}
                                value={category}
                                onChange={setCategory}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Tags</label>
                            <Select
                                name="tags"
                                options={tagOptions}
                                isMulti={true}
                                value={tags}
                                onChange={setTags}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image"
                                   onChange={(e) => setImage(e.target.files[0])}/>
                            {imageUrl &&
                                <a href={imageUrl} target="_blank" rel='noreferrer' download>Click here to download
                                    image</a>}
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Outlets <i>(Leave empty to add item for all
                                outlets)</i></label>
                            <Select
                                name="outlets"
                                options={outletOptions}
                                isMulti={true}
                                value={outlets}
                                onChange={setOutlets}/>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Timings</label>
                            <div className='table-responsive'>
                                <table
                                    id="timings"
                                    className='table datatable'
                                >
                                    <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Closed</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Monday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.monday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           monday: {...timings.monday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.monday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           monday: {...timings.monday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.monday.closed} onChange={(e) => {
                                            console.log(e.target.checked);
                                            setTimings({
                                                ...timings,
                                                monday: {...timings.monday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Tuesday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.tuesday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           tuesday: {...timings.tuesday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.tuesday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           tuesday: {...timings.tuesday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.tuesday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                tuesday: {...timings.tuesday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Wednesday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.wednesday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           wednesday: {...timings.wednesday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.wednesday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           wednesday: {...timings.wednesday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.wednesday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                wednesday: {...timings.wednesday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Thursday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.thursday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           thursday: {...timings.thursday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.thursday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           thursday: {...timings.thursday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.thursday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                thursday: {...timings.thursday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Friday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.friday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           friday: {...timings.friday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.friday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           friday: {...timings.friday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.friday.closed} onChange={(e) => {

                                            setTimings({
                                                ...timings,
                                                friday: {...timings.friday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Saturday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.saturday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           saturday: {...timings.saturday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.saturday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           saturday: {...timings.saturday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.saturday.closed} onChange={(e) => {

                                            setTimings({
                                                ...timings,
                                                saturday: {...timings.saturday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Sunday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.sunday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           sunday: {...timings.sunday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.sunday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           sunday: {...timings.sunday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.sunday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                sunday: {...timings.sunday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    );
}

export default EditDeal;
