import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Select from "react-select";
import {getCoupon, updateCoupon} from "../../services/CouponService";
import {useParams} from "react-router";
import {upload} from "../../store/utility/action";

const EditCoupon = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [code, setCode] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [details, setDetails] = useState('');
    const [link, setLink] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])

    const loadDetails = async () => {
        const coupon = await getCoupon(params.coupon);
        console.log(coupon);
        let _tags = [];
        if (coupon) {
            setTitle(coupon.title);
            setCode(coupon.code);
            setShortDescription(coupon.shortDescription);
            setDetails(coupon.details);
            setLink(coupon.link);
            setImageUrl(coupon.image);
            if (coupon.expiryDate) {
                setExpiryDate(coupon.expiryDate.split('T')[0]);
            }
            if (coupon.category) {
                setCategory({label: coupon.category.title, value: coupon.category._id});
            }
            for (const tag of coupon.tags) {
                _tags.push({label: tag.title, value: tag._id})
            }
            setTags(_tags);
        }
    }

    useEffect(() => {
        loadDetails();
    }, [params.coupon]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage(image);
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const data = {
            title,
            code,
            link,
            shortDescription,
            details,
            expiryDate,
            category: category.value,
            tags: _tags,
            ...(imageUrl !== '' && {image: imageUrl,}),
        };
        await updateCoupon(params.coupon, data);
        setLoading(false);
    };

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Update Coupon</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title"
                                       placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="code" className="form-label">Coupon Code <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="code" name="code"
                                       placeholder="Coupon Code" value={code}
                                       onChange={(e) => setCode(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                            { imageUrl &&  <a href={imageUrl} target="_blank" rel='noreferrer' download>Click here to download image</a>}
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="short-description" className="form-label">Short Description</label>
                            <textarea className="form-control" id="short-description" rows="2" value={shortDescription}
                                      onChange={(e) => setShortDescription(e.target.value)}/>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="details" className="form-label">Details</label>
                            <textarea className="form-control" id="details" rows="8" value={details}
                                      onChange={(e) => setDetails(e.target.value)}/>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="link" className="form-label">Link</label>
                                <input type="text" className="form-control" id="link" name="link"
                                       placeholder="Link" value={link}
                                       onChange={(e) => setLink(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="expiry-date" className="form-label">Expiry Date</label>
                                <input type="date" className="form-control" id="expiry-date" name="expiry-date"
                                       placeholder="Expiry Date" value={expiryDate}
                                       onChange={(e) => setExpiryDate(e.target.value)}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Category</label>
                            <Select
                                name="form-field-name"
                                options={categoryOptions}
                                value={category}
                                onChange={setCategory} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Tags</label>
                            <Select
                                name="form-field-name"
                                options={tagOptions}
                                isMulti={true}
                                value={tags}
                                onChange={setTags} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    );
}

export default EditCoupon;
