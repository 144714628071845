import React, {useEffect, useState} from 'react';
import {addOutlet, getAllStores, updateOutlet} from '../../services/OutletService';
import Select from "react-select";
import {useSelector} from "react-redux";
import $ from "jquery";
import {updateStore} from "../../services/StoreService";
import baseConfig from "../../Config";
import {useNavigate} from "react-router";
import {useSearchParams, useLocation} from "react-router-dom";


const ManageOutlets = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState(null);
    const locations = useSelector((state) => state.common.locations);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [emails, setEmails] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [locality, setLocality] = useState(null);
    const [timings, setTimings] = useState({
        monday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        tuesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        wednesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        thursday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        friday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        saturday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        sunday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            store,
            ...(phoneNumbers !== '' && {phoneNumbers: phoneNumbers.split(',')}),
            ...(emails !== '' && {emails: emails.split(',')}),
            address,
            postcode,
            timings,
            city: city.value,
            state: state.value,
            ...(locality && {locality: locality.value}),
            ...((latitude && longitude) && {coordinates: {latitude, longitude}})
        };
        const res = await addOutlet(data);
        if (res) {
            getOutletList();
            e.target.reset();
            setPhoneNumbers('');
            setEmails('');
            setState(null);
            setCity(null);
            setLocality(null);
            setAddress('');
            setPostcode('');
            setLatitude(0);
            setLongitude(0);
            setTimings({
                monday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                tuesday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                wednesday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                thursday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                friday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                saturday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                sunday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
            });
        }
        setLoading(false);
    };

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        let _states = [];
        let _cities = [];
        let _localities = [];
        locations.forEach((i) => {
            if (!i.deleted) {
                if (i.type === 'State') {
                    _states.push({value: i._id, label: i.title},);
                } else if (i.type === 'City') {
                    _cities.push({value: i._id, label: i.title},);
                } else {
                    _localities.push({value: i._id, label: i.title},);
                }
            }
        });
        setStates(_states);
        setCities(_cities);
        setLocalities(_localities);
    }, [])

    useEffect(() => {
        loadAllStores();
    }, []);


    function getOutletList() {
        try {
            $('#outlets').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#outlets').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        if (row[4] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateOutlet(rowData[7], {
                                status: rowData[4] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getOutletList();
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateOutlet(rowData[7], {
                                deleted: true
                            })
                            getOutletList();
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 8
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/outlet/list/${store}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    useEffect(() => {
        getOutletList();
        if (store !== null) {
            setSearchParams({["store"]: store})
        }
    }, [store]);

    useEffect(() => {
        if (store == null && stores.length > 0) {
            const _store = searchParams.get('store');
            if (_store) {
                setStore(_store);
                stores.forEach((i) => {
                    if (i.value === _store) {
                        setSelectedStoreOption(i);
                    }
                });
            }
        }
    }, [searchParams, stores]);

    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Select Store</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Store <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={stores}
                                onChange={(e) => {
                                    setStore(e.value);
                                    setSelectedStoreOption(e);
                                }}
                                value={selectedStoreOption}
                                required={true}/>
                        </div>
                    </div>
                </div>
            </div>

            {store && <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Add Outlet</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="phone-number" className="form-label">Phone Numbers</label>
                                    <input type="text" className="form-control" id="phone-number" name="phone-number"
                                           placeholder="Phone numbers (Separated by comma)" value={phoneNumbers}
                                           onChange={(e) => setPhoneNumbers(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="emails" className="form-label">Emails</label>
                                    <input type="text" className="form-control" id="emails" name="emails"
                                           placeholder="Emails (Separated by comma)" value={emails}
                                           onChange={(e) => setEmails(e.target.value)}/>
                                </div>
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="address" className="form-label">Address <span
                                    className='text-danger'>(required)</span></label>
                                <textarea className="form-control" id="address" rows="5" value={address}
                                          onChange={(e) => setAddress(e.target.value)} required={true}/>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="postcode" className="form-label">Postcode <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="postcode" name="postcode"
                                           placeholder="Postcode" value={postcode}
                                           onChange={(e) => setPostcode(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="latitude" className="form-label">Latitude</label>
                                    <input type="number" className="form-control" id="latitude" name="latitude"
                                           placeholder="Latitude" value={latitude}
                                           onChange={(e) => setLatitude(parseInt(e.target.value))}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="longitude" className="form-label">Longitude</label>
                                    <input type="number" className="form-control" id="longitude" name="longitude"
                                           placeholder="Longitude" value={longitude}
                                           onChange={(e) => setLongitude(parseInt(e.target.value))}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="state" className="form-label">State <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="form-field-name"
                                    options={states}
                                    onChange={setState}
                                    value={state}
                                    required={true}/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="city" className="form-label">City <span
                                    className='text-danger'>(required)</span></label>
                                <Select
                                    name="form-field-name"
                                    options={cities}
                                    onChange={setCity}
                                    value={city}
                                    required={true}/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="locality" className="form-label">Locality</label>
                                <Select
                                    name="form-field-name"
                                    options={localities}
                                    value={locality}
                                    onChange={setLocality}/>
                            </div>


                            <div className="mb-3 mt-3 col-12">
                                <label htmlFor="type" className="form-label">Timings</label>
                                <div className='table-responsive'>
                                    <table
                                        id="new-timings"
                                        className='table datatable'
                                    >
                                        <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Closed</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Monday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.monday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               monday: {...timings.monday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.monday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               monday: {...timings.monday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.monday.closed} onChange={(e) => {
                                                console.log(e.target.checked);
                                                setTimings({
                                                    ...timings,
                                                    monday: {...timings.monday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Tuesday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.tuesday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               tuesday: {...timings.tuesday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.tuesday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               tuesday: {...timings.tuesday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.tuesday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    tuesday: {...timings.tuesday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Wednesday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.wednesday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               wednesday: {...timings.wednesday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.wednesday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               wednesday: {...timings.wednesday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.wednesday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    wednesday: {...timings.wednesday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Thursday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.thursday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               thursday: {...timings.thursday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.thursday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               thursday: {...timings.thursday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.thursday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    thursday: {...timings.thursday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.friday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               friday: {...timings.friday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.friday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               friday: {...timings.friday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.friday.closed} onChange={(e) => {

                                                setTimings({
                                                    ...timings,
                                                    friday: {...timings.friday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Saturday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.saturday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               saturday: {...timings.saturday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.saturday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               saturday: {...timings.saturday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.saturday.closed} onChange={(e) => {

                                                setTimings({
                                                    ...timings,
                                                    saturday: {...timings.saturday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Sunday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.sunday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               sunday: {...timings.sunday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.sunday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               sunday: {...timings.sunday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.sunday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    sunday: {...timings.sunday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                        </button>

                    </form>

                </div>
            </div>}
            {store &&
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Manage Outlets</h5>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table
                                id="outlets"
                                className='table datatable'
                            >
                                <thead>
                                <tr>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Locality</th>
                                    <th>Postcode</th>
                                    <th>Status</th>
                                    <th>Added Date</th>
                                    <th>Change Status</th>
                                    <th>Delete</th>
                                    <th>Details</th>
                                    <th>Added By</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>}
        </>
    );
}

export default ManageOutlets;
