import {useLocation, useNavigate, useParams} from "react-router";
import React, {useEffect} from "react";
import $ from "jquery";
import baseConfig from "../../Config";
import {updateTeamMember} from "../../services/TeamService";

const ManageTeamMember = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getMembersList();
    }, []);

    const getMembersList = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger">Inactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (row[3] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            const data = {
                                status: rowData[3] === 'Active' ? 'Inactive' : 'Active'
                            }
                            await updateTeamMember(rowData[5], data);
                            getMembersList();
                        })
                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            // console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            const data = {
                                deleted: true
                            }
                            await updateTeamMember(rowData[7], data);
                            getMembersList();
                        })
                    },
                    "targets": 7
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/team/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }
    return <div>

        <div className="card mt-3">
            <div className="card-header">
                <h5 className="card-title">Manage Team Members</h5>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <table
                        id="table"
                        className='table datatable'
                    >
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Permissions</th>
                            <th>Status</th>
                            <th>Added Date</th>
                            <th>Change Status</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>Added By</th>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>;
}

export default ManageTeamMember;
