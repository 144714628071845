import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import "react-select-2/dist/css/react-select-2.css";
import {
    assignGoldMembership,
    getGoldMembershipDetails,
    getUserDetails,
    updateTransactionStatus
} from "../../services/UserService";
import * as moment from "moment";
import $ from "jquery";
import baseConfig from "../../Config";
import toast from "react-hot-toast";

const UserDetails = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [referralCode, setReferralCode] = useState(null);
    const [gender, setGender] = useState(null);
    const [balance, setBalance] = useState(0);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [referrer, setReferrer] = useState(null);

    const getDetails = async () => {
        const details = await getUserDetails(id);
        console.log(details);
        if (details) {
            setName(details.name);
            setProfilePic(details.profilePic);
            setPhoneNumber(details.phoneNumber);
            setReferralCode(details.referralCode);
            setEmail(details.email);
            setBalance(details.balance ?? 0);
        }
    };

    const getMembershipDetails = async () => {
        const details = await getGoldMembershipDetails(id);
        console.log(details);
        if (details) {
            setCardNumber(details.cardNumber);
            setExpiryDate(details.expiryDate);
            setReferrer(details.referrer);
        }
    }


    const getWithdrawals = () => {
        try {
            $('#withdrawals').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#withdrawals').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="text-danger">- ₹${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 1
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Pending') {
                            return `<p class="badge bg-primary-light">Pending</p>`;
                        } else if (data === 'Completed') {
                            return `<p class="badge bg-success-light">Completed</p>`;
                        } else if (data === 'Rejected') {
                            return `<p class="badge bg-danger-light">Rejected</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },

                {

                    "render": function (data, type, row) {
                        if (row[2] !== 'Completed') {
                            return `
                        <select class="form-select" id="${data}-status">
                          <option value="Pending" ${row[2] === 'Pending' ? 'selected' : ''}>Pending</option>
                          <option value="Completed" ${row[2] === 'Completed' ? 'selected' : ''}>Completed</option>
                          <option value="Rejected" ${row[2] === 'Rejected' ? 'selected' : ''}>Rejected</option>
                        </select>
                        `;
                        } else {
                            return `<p class="badge bg-success-light">Completed</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(document).ready(function() {
                            $(`#${cellData}-status`).change(async function(e) {
                                const res = await updateTransactionStatus(rowData[3], e.target.value);
                                if (res) {
                                    toast.success('Withdrawal status updated successfully');
                                    getWithdrawals();
                                }
                            });
                        });
                    },
                    "targets": 3
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/user/transactions/${id}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    const getOrders = () => {
        try {
            $('#orders').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#orders').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary">#${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="text-danger">₹ ${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'No') {
                            return ``;
                        } else if (data === 'Yes') {
                            return `<p class="badge bg-success-light">Yes</p>`;
                        }
                        return ``;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Details</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`/orders/detail/${cellData}`);
                        })
                    },
                    "targets": 6
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/user/orders/${id}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    const assignGoldMembershipToUser = async () => {
        const res = await assignGoldMembership(id);
        if (res) {
            toast.success('Gold membership assigned successfully');
            await getMembershipDetails();
        }
    }

    useEffect(() => {
        getDetails();
        getMembershipDetails();
        getWithdrawals();
        getOrders();
    }, [id]);


    return (
        <>
            <div className="card mb-5">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">User Details</h5>
                    <button className="btn btn-primary" onClick={() => {
                        assignGoldMembershipToUser();
                    }}>Assign Gold Membership</button>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            {(profilePic != null && profilePic.length > 0) && <div className={'col-12 d-flex justify-content-center mb-5'}>
                                <div className="figure"><img src={profilePic} style={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '100px',
                                    objectFit: 'cover',
                                    border: '1px solid #000'
                                }} alt={'profile-pic'}/></div>
                            </div>}
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone-number"
                                        name="phone-number"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Referral Code
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="referral-code"
                                        name="referral-code"
                                        placeholder="Referral Code"
                                        value={referralCode}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Balance
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="balance"
                                        name="balance"
                                        placeholder="Balance"
                                        value={balance.toString()}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Membership Details</h5>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Card Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="card-number"
                                        name="card-number"
                                        placeholder="Card Number"
                                        value={cardNumber}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Expiry Date
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="expiry-date"
                                        name="expiry-date"
                                        placeholder="Expiry Date"
                                        value={moment(expiryDate).format('DD MMMM YYYY')}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Referrer
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="referrer"
                                        name="referrer"
                                        placeholder="Referrer"
                                        value={referrer}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Withdrawals</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="withdrawals"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Change Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Orders</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="orders"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Store</th>
                                <th>Item</th>
                                <th>Total Amount</th>
                                <th>Free</th>
                                <th>Date</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetails;
