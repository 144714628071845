import React, {useEffect, useState} from "react";
import Select from "react-select";
import {upload} from "../../store/utility/action";
import {addCustomSectionContent, getAllCategoryOptions} from "../../services/CustomSectionService";
import {getAllDealOptions} from "../../services/DealService";
import {getAllCouponOptions} from "../../services/CouponService";
import {getAllStoreOptions} from "../../services/StoreService";

const AddCustomSectionContent = ({section, sectionType}) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [url, setUrl] = useState(null);
    const [type, setType] = useState(null);
    const [image, setImage] = useState(null);
    const [item, setItem] = useState(null);
    const itemTypeOptions = [
        { value: 'Store', label: 'Store' },
        { value: 'Coupon', label: 'Coupon' },
        { value: 'Deal', label: 'Deal' },
        { value: 'Category', label: 'Category' },
    ];
    const [itemOptions, setItemOptions] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage(image);
        }
        const data = {
            section,
            ...(imageUrl && {image: imageUrl,}),
            ...(title && {title,}),
            ...(item && {item: item.value,}),
            ...(description && {description,}),
            ...(url && {url,}),
            ...(type && {type: type.value,}),
        };
        const res = await addCustomSectionContent(data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    const getDealOptions = async () => {
        let page = 1;
        const deals = [];
        while (page !== 0) {
            const _deals = await getAllDealOptions(page, 100);
            if (_deals.length > 0) {
                deals.push(..._deals);
                page++;
            } else {
                page = 0;
            }
        }
        setItemOptions(deals);
    }

    const getCouponOptions = async () => {
        let page = 1;
        const deals = [];
        while (page !== 0) {
            const _deals = await getAllCouponOptions(page, 100);
            if (_deals.length > 0) {
                deals.push(..._deals);
                page++;
            } else {
                page = 0;
            }
        }
        setItemOptions(deals);
    }

    const getStoreOptions = async () => {
        let page = 1;
        const deals = [];
        while (page !== 0) {
            const _deals = await getAllStoreOptions(page, 100);
            if (_deals.length > 0) {
                deals.push(..._deals);
                page++;
            } else {
                page = 0;
            }
        }
        setItemOptions(deals);
    }

    const getCategoryOptions = async () => {
        let page = 1;
        const deals = [];
        while (page !== 0) {
            const _deals = await getAllCategoryOptions(page, 100);
            if (_deals.length > 0) {
                deals.push(..._deals);
                page++;
            } else {
                page = 0;
            }
        }
        setItemOptions(deals);
    }

    useEffect(() => {
        if (type) {
            if (type.value === 'Deal') {
                getDealOptions();
            } else if (type.value === 'Coupon') {
                getCouponOptions();
            } else if (type.value === 'Store') {
                getStoreOptions();
            } else if (type.value === 'Category') {
                getCategoryOptions();
            }
        }
    }, [type])

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Add Content</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Item Type</label>
                            <Select
                                name="form-field-name"
                                options={itemTypeOptions}
                                onChange={setType}
                                value={type}
                                required={true} />
                        </div>
                        <div className=" col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="item" className="form-label">Item ID</label>
                                <Select
                                    name="form-field-name"
                                    options={itemOptions}
                                    onChange={setItem}
                                    value={item}
                                    required={true} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <input type="text" className="form-control" id="title" name="title"
                                       placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="url" className="form-label">URL</label>
                                <input type="text" className="form-control" id="url" name="url"
                                       placeholder="URL" value={url}
                                       onChange={(e) => setUrl(e.target.value)}/>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea className="form-control" id="description" rows="5" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    </>;
}

export default AddCustomSectionContent;