import React from 'react';

const Dashboard = () => {
    return (
        <>
           DASHBOARD
        </>
    );
}

export default Dashboard;
