import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAllStores} from "../../services/OutletService";
import Select from "react-select";
import {upload} from "../../store/utility/action";
import {addItem, updateItem} from "../../services/ItemService";
import {addPhoto, updatePhoto} from "../../services/PhotoService";
import $ from "jquery";
import baseConfig from "../../Config";

const ManagePhotos = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState('');

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        loadAllStores();
    }, []);

    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        getPhotoList();
        if (store !== null) {
            setSearchParams({["store"]: store})
        }
    }, [store]);

    function getPhotoList() {
        try {
            $('#photos').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#photos').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (row[2] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updatePhoto(rowData[4], {
                                status: rowData[2] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getPhotoList();
                        })
                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updatePhoto(rowData[5], {
                                deleted: true
                            })
                            getPhotoList();
                        })
                    },
                    "targets": 5
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/photo/list/${store}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("files", image);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage();
        }
        const data = {
            store,
            image: imageUrl,
            category: category.value,
        };
        const res = await addPhoto(data);
        if (res) {
            getPhotoList();
            e.target.reset();
            setCategory('');
        }
        setLoading(false);
    };


    return (
       <>
           <div className="card mb-3">
               <div className="card-header">
                   <h5 className="card-title">Select Store</h5>
               </div>
               <div className='card-body'>
                   <div className='row'>
                       <div className="mb-3 col-12">
                           <label htmlFor="type" className="form-label">Store <span
                               className='text-danger'>(required)</span></label>
                           <Select
                               name="form-field-name"
                               options={stores}
                               onChange={(e) => {
                                   setStore(e.value);
                                   setSelectedStoreOption(e);
                               }}
                               value={selectedStoreOption}
                               required={true}/>
                       </div>
                   </div>
               </div>
           </div>
           {store && <div className="card mb-3">
               <div className="card-header">
                   <h5 className="card-title">Add Photo</h5>
               </div>
               <div className="card-body">
                   <form onSubmit={handleSubmit}>
                       <div className="row">
                           <div className="mb-3 col-sm-12 col-md-6">
                               <label htmlFor="image" className="form-label">Image</label>
                               <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                           </div>
                           <div className="mb-3 col-sm-12 col-md-6">
                               <label htmlFor="type" className="form-label">Category</label>
                               <Select
                                   name="category"
                                   options={categoryOptions}
                                   value={category}
                                   onChange={setCategory} />
                           </div>
                       </div>
                       <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                           <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                       </button>

                   </form>

               </div>
           </div>}
           {store &&
               <div className="card mt-3">
                   <div className="card-header">
                       <h5 className="card-title">Manage Photos</h5>
                   </div>
                   <div className="card-body">
                       <div className='table-responsive'>
                           <table
                               id="photos"
                               className='table datatable'
                           >
                               <thead>
                               <tr>
                                   <th>Image</th>
                                   <th>Category</th>
                                   <th>Status</th>
                                   <th>Added Date</th>
                                   <th>Change Status</th>
                                   <th>Details</th>
                                   <th>Added By</th>
                               </tr>
                               </thead>
                               <tbody>

                               </tbody>
                           </table>
                       </div>


                   </div>
               </div>}
       </>
    );
}

export default ManagePhotos;
