import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  categories: [],
  tags: [],
  locations: [],
  configs: [],
  permissions: [],
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    allCategoriesLoaded(state, action) {
      state.categories.push(...action.payload);
    },
    operationFailed(state, action) {
      toast.error(action.payload.message, {});
    },
    categoryUpdateSuccess(state, action) {
      if (action.payload) {
        let index = state.categories.findIndex(
          (cat) => cat._id === action.payload._id
        );
        if (index >= 0) {
          state.categories[index] = action.payload;
          toast.success("Category updated successfully", {});
        }
      }
    },
    categoryAddSuccess(state, action) {
      state.categories.push(action.payload);
      toast.success("Category added successfully", {});
    },
    tagAddSuccess(state, action) {
      state.tags.push(action.payload);
      toast.success("Tag added successfully", {});
    },
    allTagsLoaded(state, action) {
      state.tags.push(...action.payload);
    },
    tagUpdateSuccess(state, action) {
      if (action.payload) {
        let index = state.tags.findIndex(
          (cat) => cat._id === action.payload._id
        );
        if (index >= 0) {
          state.tags[index] = action.payload;
          toast.success("Tag updated successfully", {});
        }
      }
    },
    configAddSuccess(state, action) {
      state.configs.push(action.payload);
      toast.success("Config added successfully", {});
    },
    allConfigsLoaded(state, action) {
      state.configs.push(...action.payload);
    },
    configUpdateSuccess(state, action) {
      if (action.payload) {
        let index = state.configs.findIndex(
          (cat) => cat._id === action.payload._id
        );
        if (index >= 0) {
          state.configs[index] = action.payload;
          toast.success("Config updated successfully", {});
        }
      }
    },
    locationAddSuccess(state, action) {
      state.locations.push(action.payload);
      toast.success("Location added successfully", {});
    },
    allLocationsLoaded(state, action) {
      state.locations.push(...action.payload);
    },
    locationUpdateSuccess(state, action) {
      if (action.payload) {
        let index = state.locations.findIndex(
          (cat) => cat._id === action.payload._id
        );
        if (index >= 0) {
          state.locations[index] = action.payload;
          toast.success("Location updated successfully", {});
        }
      }
    },
    allPermissionsLoaded(state, action) {
      state.permissions.push(...action.payload);
    },
  },
});

export const {
  allCategoriesLoaded,
  categoryAddSuccess,
  categoryUpdateSuccess,
  tagAddSuccess,
  allTagsLoaded,
  tagUpdateSuccess,
  configAddSuccess,
  allConfigsLoaded,
  configUpdateSuccess,
  locationAddSuccess,
  allLocationsLoaded,
  locationUpdateSuccess,
  operationFailed,
  allPermissionsLoaded,
} = commonSlice.actions;
export default commonSlice.reducer;
