import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";
import store from "../../store";
import {
    updateCategory
} from "../../store/common/actions";
import moment from "moment";

const ManageCategories = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const allCategories = useSelector((state) => state.common.categories)
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            data: categories,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary" style="cursor: pointer">${data[1]}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData[0]}`);
                        })

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        if (row[4] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const category = allCategories.find((i) => i._id === rowData[6]);
                            if (category) {
                                store.dispatch(updateCategory(rowData[6], {
                                    ...category,
                                    status: rowData[4] === 'Active' ? 'Inactive' : 'Active'
                                }));
                            }
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const category = allCategories.find((i) => i._id === rowData[7]);
                            if (category) {
                                store.dispatch(updateCategory(rowData[7], {
                                    ...category,
                                    deleted: true
                                }));
                            }
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 8
                },
            ]
        });
    }, [categories]);

    useEffect(() => {
        let _categories = [];
        allCategories.forEach((cat) => {
            if (!cat.deleted) {
                _categories.push([cat.image ?? '', cat.title, cat.parent ? [cat.parent._id, cat.parent.title] : ['', ''], cat.slug ?? '', cat.status, moment(cat.createdAt || "").format('MMMM Do YYYY'), cat._id, cat._id, cat._id, cat.addedBy != null ? cat.addedBy.name : ''])
            }
        });
        console.log(_categories);
        setCategories(_categories);
    }, [allCategories])

    return (
        <div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Categories</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Parent</th>
                                <th>Slug</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Change Status</th>
                                <th>Delete</th>
                                <th>Details</th>
                                <th>Added By</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageCategories;
