import { createSlice } from '@reduxjs/toolkit';
import toast from "react-hot-toast";


const initialState = { token: null, email: null, name: null, username: null, profilePic: null, permissions: [] }

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.username = action.payload.username;
            state.profilePic = action.payload.profilePic;
            state.permissions = action.payload.permissions;
            localStorage.setItem("token", action.payload.token);
            toast.success("Login successfully");
        },
        loginFailed(state, action) {
            toast.error(action.payload.message, {});
        },
        refreshTokenSuccess(state, action) {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.username = action.payload.username;
            state.profilePic = action.payload.profilePic;
            state.permissions = action.payload.permissions;
            localStorage.setItem("token", action.payload.token);
        },
        refreshTokenFailed(state, action) {
            state.token = null;
            state.email = null;
            state.name = null;
            state.username = null;
            state.profilePic = null;
            state.permissions = [];
            localStorage.setItem("token", null);

        },
        logoutSuccess(state) {
            state.token = null;
            state.email = null;
            state.name = null;
            state.username = null;
            state.profilePic = null;
            state.permissions = [];
            localStorage.setItem("token", null);
        },
        profileUpdateSuccess(state, action) {
            state.email = action.payload.email;
            state.name = action.payload.name;
            toast.success("Profile updated successfully", {});
        },
        profileUpdateFailed(state, action) {
            toast.error(action.payload.message, {});
        },
    },
})

export const { loginSuccess, loginFailed, refreshTokenSuccess, refreshTokenFailed, logoutSuccess, profileUpdateSuccess, profileUpdateFailed } = authSlice.actions
export default authSlice.reducer
