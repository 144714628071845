import React from 'react';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const Base = ({ children }) => {

    return (
        <div className="main-wrapper">
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {children}
                </div>
            </div>
        </div>
        );
}

export default Base;
