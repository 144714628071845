import Select from "react-select";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {addCustomSection} from "../../services/CustomSectionService";

const AddCustomSection = () => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState(null);
    const [locations, setLocations] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageId, setPageId] = useState(null);
    const [loading, setLoading] = useState(false);
    const typeOptions = [
        { value: 'Category Type 1', label: 'Category Type 1' },
        { value: 'Gallery Type 1', label: 'Gallery Type 1' },
        { value: 'Gallery Type 2', label: 'Gallery Type 2' },
        { value: 'Gallery Type 3', label: 'Gallery Type 3' },
        { value: 'Gallery Type 4', label: 'Gallery Type 4' },
        { value: 'Item Custom', label: 'Item Custom' },
        { value: 'Item Standard', label: 'Item Standard' },
    ];
    const [locationOptions, setLocationOptions] = useState([]);
    const allLocations = useSelector((state) => state.common.locations);
    const pageOptions = [
        { value: 'Home', label: 'Home' },
        { value: 'Offer & Deals', label: 'Offer & Deals' },
        { value: 'Category', label: 'Category' },
    ];

    useEffect(() => {
        let _locations = [];
        allLocations.forEach((i) => {
            _locations.push({value: i._id, label: i.title},);
        });
        setLocationOptions(_locations);
    }, [allLocations]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const _locations = [], _pages = [];
        for (const loc of locations) {
            _locations.push(loc.value);
        }
        for (const page of pages) {
            _pages.push(page.value);
        }
        const data = {
            title,
            type: type.value,
            locations: _locations,
            pages: _pages,
            ...(pageId && {pageId: pageId})
        };
        await addCustomSection(data);
        e.target.reset();
        setLoading(false);
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Add Custom Section</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title"
                                       placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Section Type <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="type"
                                options={typeOptions}
                                value={type}
                                onChange={setType}/>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="locations" className="form-label">Locations</label>
                            <Select
                                name="locations"
                                options={locationOptions}
                                isMulti={true}
                                value={locations}
                                onChange={setLocations}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="page" className="form-label">Page</label>
                            <Select
                                name="page"
                                options={pageOptions}
                                isMulti={true}
                                value={pages}
                                onChange={setPages}/>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="page-id" className="form-label">Page ID/Slug</label>
                                <input type="text" className="form-control" id="page-id" name="page-id"
                                       placeholder="Page ID/Slug" value={pageId}
                                       onChange={(e) => setPageId(e.target.value)}/>
                            </div>
                        </div>

                    </div>
                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    );
}

export default AddCustomSection;