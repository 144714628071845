import React, {useEffect, useState} from 'react';
import store from "../../store";
import {addConfig} from "../../store/common/actions";
import {useSelector} from "react-redux";
import Select from "react-select";
import {upload} from "../../store/utility/action";
import {addStore, getStore, updateStore} from "../../services/StoreService";
import {useParams} from "react-router";

const EditSeller = () => {
    const params = useParams();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [description, setDescription] = useState("");
    const [type, setType] = useState({ value: 'Merchant', label: 'Merchant' });
    const typeOptions = [
        { value: 'Brand', label: 'Brand' },
        { value: 'Merchant', label: 'Merchant' },
    ];
    const allCategories = useSelector((state) => state.common.categories)
    const allTags = useSelector((state) => state.common.tags)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [category, setCategory] = useState(null);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (logo !== null) {
            imageUrl = await uploadImage();
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const data = {
            phoneNumber,
            name,
            ...(imageUrl !== '' && {logo: imageUrl,}),
            description,
            type: type.value,
            category: category.value,
            tags: _tags
        };
        const res = await updateStore(params.store, data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);
    };

    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("files", logo);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadDetails = async () => {
        const store = await getStore(params.store);
        let _tags = [];
        if (store) {
            setPhoneNumber(store.phoneNumber);
            setName(store.name);
            setDescription(store.description);
            setCategory({label: store.category.title, value: store.category._id});
            setLogoUrl(store.logo);
            for (const tag of store.tags) {
                _tags.push({label: tag.title, value: tag._id})
            }
            setTags(_tags);
        }
    }

    useEffect(() => {
        loadDetails();
    }, [params.store]);

    useEffect(() => {
        let _categories = [];
        allCategories.forEach((cat) => {
            _categories.push({ value: cat._id, label: cat.title },)
        });
        setCategoryOptions(_categories);
    }, [allCategories, type]);

    useEffect(() => {
        let _tags = [];
        allTags.forEach((cat) => {
            if (!cat.deleted) {
                _tags.push({ value: cat._id, label: cat.title },)
            }
        });
        setTagOptions(_tags);
    }, [allTags, type.value]);

    return (<>
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Update Store</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Phone Number <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="phone-number" name="phone-number" placeholder="Phone number" value={phoneNumber}
                                       onChange={(e) => setPhoneNumber(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Name <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="name" name="name" placeholder="Name" value={name}
                                       onChange={(e) => setName(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea className="form-control" id="description" rows="5" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Type <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={typeOptions}
                                onChange={setType}
                                value={type}
                                required={true} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="image" className="form-label">Logo</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setLogo(e.target.files[0])} />
                            { logoUrl &&  <a href={logoUrl} target="_blank" rel='noreferrer' download>Click here to download image</a>}
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Category <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                required={true}
                                options={categoryOptions}
                                value={category}
                                onChange={setCategory} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Tags</label>
                            <Select
                                name="form-field-name"
                                isMulti={true}
                                options={tagOptions}
                                value={tags}
                                onChange={setTags} />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default EditSeller;
