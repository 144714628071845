import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getOffer} from "../../services/OfferService";
import {getAllOutlets} from "../../services/OutletService";
import {addItem, getItem, updateItem} from "../../services/ItemService";
import Select from "react-select";
import {upload} from "../../store/utility/action";

const EditItem = () => {
    const params = useParams();
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [outletOptions, setOutletOptions] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])

    const loadDetails = async () => {
        const item = await getItem(params.item);
        let _tags = [];
        let _outlets = [];
        if (item) {
            loadAllOutlets(item.store);
            setName(item.name);
            setPrice(item.price);
            setDescription(item.description);
            if (item.image) {
                setImageUrl(item.image);
            }
            if (item.category) {
                setCategory({label: item.category.title, value: item.category._id});
            }
            for (const tag of item.tags) {
                _tags.push({label: tag.title, value: tag._id})
            }
            setTags(_tags);
            for (const outlet of item.outlets) {
                _outlets.push({label: `${outlet.state.title},${outlet.city.title},${outlet.locality !== null ? outlet.locality.title : ''}`, value: outlet._id});
            }
            setOutlets(_outlets);
        }
    }

    const loadAllOutlets = async (store) => {
        let _loadedOutlets = await getAllOutlets(store);
        let _outlets = [];
        for (const outlet of _loadedOutlets) {
            _outlets.push({label: `${outlet.state.title},${outlet.city.title},${outlet.locality !== null ? outlet.locality.title : ''}`, value: outlet._id});
        }
        setOutletOptions(_outlets);
    }

    useEffect(() => {
        loadDetails();
    }, [params.item]);


    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("files", image);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage();
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const _outlets = [];
        for (const o of outlets) {
            _outlets.push(o.value);
        }
        const data = {
            name,
            price,
            ...(imageUrl !== '' && {image: imageUrl,}),
            description,
            outlets: _outlets,
            category: category.value,
            tags: _tags
        };
        await updateItem(params.item, data);
        setLoading(false);
    };

    return (
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Edit Item</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="name" name="name"
                                       placeholder="Name" value={name}
                                       onChange={(e) => setName(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="price" className="form-label">Price <span
                                    className='text-danger'>(required)</span></label>
                                <input type="number" className="form-control" id="price" name="price"
                                       placeholder="Price" value={price}
                                       onChange={(e) => setPrice(parseFloat(e.target.value))} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea className="form-control" id="description" rows="5" value={description}
                                      onChange={(e) => setDescription(e.target.value)}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Category</label>
                            <Select
                                name="category"
                                options={categoryOptions}
                                value={category}
                                onChange={setCategory} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="type" className="form-label">Tags</label>
                            <Select
                                name="tags"
                                options={tagOptions}
                                isMulti={true}
                                value={tags}
                                onChange={setTags} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                            { imageUrl &&  <a href={imageUrl} target="_blank" rel='noreferrer' download>Click here to download image</a>}
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Outlets <i>(Leave empty to add item for all outlets)</i></label>
                            <Select
                                name="outlets"
                                options={outletOptions}
                                isMulti={true}
                                value={outlets}
                                onChange={setOutlets} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    );
}

export default EditItem;
