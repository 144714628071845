import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";
import store from "../../store";
import {
    updateConfig
} from "../../store/common/actions";
import moment from "moment";

const ManageConfigs = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const allItems = useSelector((state) => state.common.configs)
    const [items, setItems] = useState([]);

    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            data: items,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (row[2] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const item = allItems.find((i) => i._id === rowData[6]);
                            if (item) {
                                store.dispatch(updateConfig(rowData[6], {
                                    ...item,
                                    status: rowData[2] === 'Active' ? 'Inactive' : 'Active'
                                }));
                            }
                        })
                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const item = allItems.find((i) => i._id === rowData[6]);
                            if (item) {
                                store.dispatch(updateConfig(rowData[6], {
                                    ...item,
                                    deleted: true
                                }));
                            }
                        })
                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 6
                },
            ]
        });
    }, [items]);

    useEffect(() => {
        let _items = [];
        allItems.forEach((i) => {
            if (!i.deleted) {
                _items.push([i.attribute, i.value, i.status, moment(i.createdAt || "").format('MMMM Do YYYY'), i._id, i._id, i._id, i.addedBy != null ? i.addedBy.name : ''])
            }
        });
        setItems(_items);
    }, [allItems])

    return (
        <div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Configs</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Attribute</th>
                                <th>Value</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Change Status</th>
                                <th>Delete</th>
                                <th>Details</th>
                                <th>Added By</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageConfigs;
