import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import { useLocation, useNavigate } from "react-router";

import baseConfig from "../../Config";
import {updateBlog} from "../../services/BlogService";


const ManageBlogs = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getBlogList();
    }, []);

    const getBlogList = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger">Inactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<input type="checkbox" checked />`;
                        } else {
                            return `<input type="checkbox" />`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            const data = {
                                featured: !cellData
                            }
                            await updateBlog(rowData[8], data);
                        })
                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        if (row[6] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            const data = {
                                status: rowData[6] === 'Active' ? 'Inactive' : 'Active'
                            }
                            await updateBlog(rowData[8], data);
                            getBlogList();
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            // console.log('CLICKED');
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 9
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            const data = {
                                deleted: true
                            }
                            await updateBlog(rowData[10], data);
                            getBlogList();
                        })
                    },
                    "targets": 10
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/blog/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    useEffect(() => {
        // let _Blogs = [];
        // allBlogs.forEach((cat) => {
        //     if (!cat.deleted) {
        //         _Blogs.push([cat.title, cat.type, cat.parent ? [cat.parent._id, cat.parent.title] : ['', ''], cat.status, moment(cat.createdAt || "").format('MMMM Do YYYY'), cat._id, cat._id, cat._id])
        //     }
        // });
        // console.log(_Blogs);
        // setBlogs(_Blogs);
    }, [])

    return (
        <div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Blogs</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Store</th>
                                    <th>Category</th>
                                    <th>Featured</th>
                                    <th>Status</th>
                                    <th>Added Date</th>
                                    <th>Change Status</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    <th>Added By</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageBlogs;
