import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import {getAllOutlets, getAllStores} from "../../services/OutletService";
import Select from "react-select";
import {addItem} from "../../services/ItemService";
import {buyPremium, getPremium, removePremium} from "../../services/StoreService";

const ManagePremium = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [price, setPrice] = useState(0);
    const [isPremium, setIsPremium] = useState(false);

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        loadAllStores();
    }, []);

    const loadAllOutlets = async () => {
        let _loadedOutlets = await getAllOutlets(store);
        let _outlets = [];
        for (const outlet of _loadedOutlets) {
            _outlets.push({label: `${outlet.state.title},${outlet.city.title},${outlet.locality !== null ? outlet.locality.title : ''}`, value: outlet._id});
        }
        setOutletOptions(_outlets);
    }

    useEffect(() => {
        if (store !== null) {
            loadAllOutlets();
            getPremiumDetails();
        }
    }, [store])

    const getPremiumDetails = async () => {
        let _premium = await getPremium(store);
        if (_premium) {
            setPrice(_premium.price);
            const _outlets = [];
            for (const outlet of _premium.outlets) {
                _outlets.push({label: `${outlet.state.title},${outlet.city.title},${outlet.locality !== null ? outlet.locality.title : ''}`, value: outlet._id});
            }
            setOutlets(_outlets)
            setIsPremium(true);
        } else {
            setIsPremium(false);
            setPrice(0);
            setOutlets([]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const _outlets = [];
        for (const o of outlets) {
            _outlets.push(o.value);
        }
        const data = {
            store,
            outlets: _outlets,
            price,
            purchasedBy: 'Admin'
        };
        const res = await buyPremium(store, data);
        if (res) {
            e.target.reset();
            setPrice(0);
            setOutlets([]);
            getPremiumDetails();
        }
        setLoading(false);
    };

    const deletePremium = async (e) => {
        setLoading(true);
        const res = await removePremium(store);
        setLoading(false);
        getPremiumDetails();
    };

    return (
      <>
          <div className="card mb-3">
              <div className="card-header">
                  <h5 className="card-title">Select Store</h5>
              </div>
              <div className='card-body'>
                  <div className='row'>
                      <div className="mb-3 col-12">
                          <label htmlFor="type" className="form-label">Store <span
                              className='text-danger'>(required)</span></label>
                          <Select
                              name="form-field-name"
                              options={stores}
                              onChange={(e) => {
                                  setStore(e.value);
                              }}
                              required={true}/>
                      </div>
                  </div>
              </div>
          </div>
          {store && <div className="card mb-3">
              <div className="card-header">
                  <h5 className="card-title">{!isPremium ? 'Add Premium' : 'Remove Premium'}</h5>
              </div>
              <div className="card-body">
                  <form onSubmit={handleSubmit}>
                      <div className="row">
                          <div className="col-sm-12 col-md-6">
                              <div className="mb-3">
                                  <label htmlFor="price" className="form-label">Price <span
                                      className='text-danger'>(required)</span></label>
                                  <input type="number" className="form-control" id="price" name="price"
                                         placeholder="Price" value={price}
                                         onChange={(e) => setPrice(parseFloat(e.target.value))} disabled={isPremium} required={true}/>
                              </div>
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6">
                              <label htmlFor="type" className="form-label">Outlets <i>(Leave empty to add item for all outlets)</i></label>
                              <Select
                                  name="outlets"
                                  options={outletOptions}
                                  isMulti={true}
                                  value={outlets}
                                  onChange={setOutlets} disabled={isPremium} />
                          </div>
                      </div>
                      {!isPremium ? <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                          <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                      </button> : <button type="button" onClick={deletePremium} className="btn btn-danger me-2" disabled={loading}>{loading &&
                          <span className="spinner-border spinner-border-sm me-2" role="status"/>} Remove Premium
                      </button>}

                  </form>

              </div>
          </div>}
      </>
    );
}

export default ManagePremium;
