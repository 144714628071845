import {useLocation, useNavigate, useParams} from "react-router";
import React, {useEffect} from "react";
import $ from "jquery";
import baseConfig from "../../Config";
import {updateTeamMember} from "../../services/TeamService";
import {updateTransactionStatus} from "../../services/StoreService";
import toast from "react-hot-toast";

const ManageWithdrawals = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getWithdrawals();
    }, []);

    const getWithdrawals = () => {
        try {
            $('#withdrawals').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#withdrawals').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<a class="text-primary" href="/stores/edit/${data[1]}">${data[0]}</a>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 1
                },
                {

                    "render": function (data, type, row) {
                        return `<p class="text-danger">- ₹${data}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Pending') {
                            return `<p class="badge bg-primary-light">Pending</p>`;
                        } else if (data === 'Completed') {
                            return `<p class="badge bg-success-light">Completed</p>`;
                        } else if (data === 'Rejected') {
                            return `<p class="badge bg-danger-light">Rejected</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },

                {

                    "render": function (data, type, row) {
                        if (row[3] !== 'Completed') {
                            return `
                        <select class="form-select" id="${data}-status">
                          <option value="Pending" ${row[4] === 'Pending' ? 'selected' : ''}>Pending</option>
                          <option value="Completed" ${row[4] === 'Completed' ? 'selected' : ''}>Completed</option>
                          <option value="Rejected" ${row[4] === 'Rejected' ? 'selected' : ''}>Rejected</option>
                        </select>
                        `;
                        } else {
                            return `<p class="badge bg-success-light">Completed</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(document).ready(function() {
                            $(`#${cellData}-status`).change(async function(e) {
                                const res = await updateTransactionStatus(rowData[4], e.target.value);
                                if (res) {
                                    toast.success('Withdrawal status updated successfully');
                                    getWithdrawals();
                                }
                            });
                        });
                    },
                    "targets": 4
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/store/transactions/all`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    return <div>

        <div className="card mb-5">
            <div className="card-header">
                <h5 className="card-title">Withdrawals</h5>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <table
                        id="withdrawals"
                        className='table datatable'
                    >
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Store</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Change Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}

export default ManageWithdrawals;
