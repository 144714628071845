import React, {useEffect, useState} from 'react';
import store from "../../store";
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import {addCategory, addLocation, updateLocation} from "../../store/common/actions";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import moment from "moment";
import {useParams} from "react-router";


const AddLocation = () => {
    const params = useParams();
    const allItems = useSelector((state) => state.common.locations)
    const [title, setTitle] = useState("");
    const [type, setType] = useState({ value: 'State', label: 'State' });
    const [loading, setLoading] = useState(false);
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState(null);
    const typeOptions = [
        { value: 'State', label: 'State' },
        { value: 'City', label: 'City' },
        { value: 'Locality', label: 'Locality' },
    ];

    useEffect(() => {
        let _items = [];
        allItems.filter((c) => c.type === (type.value === 'City' ? 'State' : type.value === 'Locality' ? 'City' : 'State')).forEach((cat) => {
            if (!cat.deleted) {
                _items.push({ value: cat._id, label: cat.title },)
            }
        });
        setParents(_items);
    }, [allItems, type.value])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            title,
            ...(parent && {parent: parent.value}),
            type: type.value
        };
        await store.dispatch(updateLocation(params.location, data));
        e.target.reset();
        setLoading(false);
    };

    useEffect(() => {
        let item = allItems.find(cat => cat._id === params.location);
        if (item) {
            setTitle(item.title);
            setType({ value: item.type, label: item.type });
            if (item.parent) {
                setParent({value: item.parent._id, label: item.parent.title});
            }
        }
    }, [allItems, params]);

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Edit Location</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Type <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={typeOptions}
                                onChange={setType}
                                value={type}
                                required={true} />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Parent</label>
                            <Select
                                name="form-field-name"
                                options={parents}
                                value={parent}
                                onChange={setParent} />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default AddLocation;
