import React, {useEffect, useState} from 'react';
import store from "../../store";
import {updateTag} from "../../store/common/actions";
import {useParams} from "react-router";
import {useSelector} from "react-redux";

const EditTag = () => {
    const params = useParams();
    const allItems = useSelector((state) => state.common.tags)
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            title,
        };
        await store.dispatch(updateTag(params.tag, data));
        e.target.reset();
        setLoading(false);
    };

    useEffect(() => {
        let item = allItems.find(i => i._id === params.tag);
        if (item) {
            setTitle(item.title);
        }
    }, [allItems, params]);

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Edit Tag</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Title" value={title}
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default EditTag;
