import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import {getAllOutlets, getAllStores} from "../../services/OutletService";
import Select from "react-select";
import {useSelector} from "react-redux";
import {upload} from "../../store/utility/action";
import {addStore} from "../../services/StoreService";
import {addItem, updateItem} from "../../services/ItemService";
import $ from "jquery";
import {updateOffer} from "../../services/OfferService";
import baseConfig from "../../Config";

const ManageItems = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState(null);
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        loadAllStores();
    }, []);

    useEffect(() => {
        getItemList();
        if (store !== null) {
            setSearchParams({["store"]: store})
        }
    }, [store]);

    useEffect(() => {
        if (store == null && stores.length > 0) {
            const _store = searchParams.get('store');
            if (_store) {
                setStore(_store);
                stores.forEach((i) => {
                    if (i.value === _store) {
                        setSelectedStoreOption(i);
                    }
                });
            }
        }
    }, [searchParams, stores]);


    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])

    const loadAllOutlets = async () => {
        let _loadedOutlets = await getAllOutlets(store);
        let _outlets = [];
        for (const outlet of _loadedOutlets) {
            _outlets.push({label: `${outlet.state.title},${outlet.city.title},${outlet.locality !== null ? outlet.locality.title : ''}`, value: outlet._id});
        }
        setOutletOptions(_outlets);
    }

    useEffect(() => {
        if (store !== null) {
            loadAllOutlets();
        }
    }, [store])

    function getItemList() {
        try {
            $('#items').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#items').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        if (row[4] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateItem(rowData[7], {
                                status: rowData[4] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getItemList();
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateItem(rowData[7], {
                                deleted: true
                            })
                            getItemList();
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 8
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/item/list/${store}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    const uploadImage = async () => {
        let formData = new FormData();
        formData.append("files", image);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage();
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const _outlets = [];
        for (const o of outlets) {
            _outlets.push(o.value);
        }
        const data = {
            store,
            name,
            price,
            ...(imageUrl !== '' && {image: imageUrl,}),
            description,
            outlets: _outlets,
            category: category.value,
            tags: _tags
        };
        const res = await addItem(data);
        if (res) {
            getItemList();
            e.target.reset();
            setName('');
            setPrice(0);
            setCategory('');
            setDescription('');
            setOutlets([]);
            setTags([]);
        }
        setLoading(false);
    };


    return (
       <>
           <div className="card mb-3">
               <div className="card-header">
                   <h5 className="card-title">Select Store</h5>
               </div>
               <div className='card-body'>
                   <div className='row'>
                       <div className="mb-3 col-12">
                           <label htmlFor="type" className="form-label">Store <span
                               className='text-danger'>(required)</span></label>
                           <Select
                               name="form-field-name"
                               options={stores}
                               onChange={(e) => {
                                   setStore(e.value);
                                   setSelectedStoreOption(e);
                               }}
                               value={selectedStoreOption}
                               required={true}/>
                       </div>
                   </div>
               </div>
           </div>
           {store && <div className="card mb-3">
               <div className="card-header">
                   <h5 className="card-title">Add Item</h5>
               </div>
               <div className="card-body">
                   <form onSubmit={handleSubmit}>
                       <div className="row">
                           <div className="col-sm-12 col-md-6">
                               <div className="mb-3">
                                   <label htmlFor="name" className="form-label">Name <span
                                       className='text-danger'>(required)</span></label>
                                   <input type="text" className="form-control" id="name" name="name"
                                          placeholder="Name" value={name}
                                          onChange={(e) => setName(e.target.value)} required={true}/>
                               </div>
                           </div>
                           <div className="col-sm-12 col-md-6">
                               <div className="mb-3">
                                   <label htmlFor="price" className="form-label">Price <span
                                       className='text-danger'>(required)</span></label>
                                   <input type="number" className="form-control" id="price" name="price"
                                          placeholder="Price" value={price}
                                          onChange={(e) => setPrice(parseFloat(e.target.value))} required={true}/>
                               </div>
                           </div>
                           <div className="mb-3 col-12">
                               <label htmlFor="description" className="form-label">Description</label>
                               <textarea className="form-control" id="description" rows="5" value={description}
                                         onChange={(e) => setDescription(e.target.value)}/>
                           </div>
                           <div className="mb-3 col-sm-12 col-md-4">
                               <label htmlFor="type" className="form-label">Category</label>
                               <Select
                                   name="category"
                                   options={categoryOptions}
                                   value={category}
                                   onChange={setCategory} />
                           </div>
                           <div className="mb-3 col-sm-12 col-md-4">
                               <label htmlFor="type" className="form-label">Tags</label>
                               <Select
                                   name="tags"
                                   options={tagOptions}
                                   isMulti={true}
                                   value={tags}
                                   onChange={setTags} />
                           </div>
                           <div className="mb-3 col-sm-12 col-md-4">
                               <label htmlFor="image" className="form-label">Image</label>
                               <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                           </div>
                           <div className="mb-3 col-12">
                               <label htmlFor="type" className="form-label">Outlets <i>(Leave empty to add item for all outlets)</i></label>
                               <Select
                                   name="outlets"
                                   options={outletOptions}
                                   isMulti={true}
                                   value={outlets}
                                   onChange={setOutlets} />
                           </div>
                       </div>
                       <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                           <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                       </button>

                   </form>

               </div>
           </div>}
           {store &&
               <div className="card mt-3">
                   <div className="card-header">
                       <h5 className="card-title">Manage Items</h5>
                   </div>
                   <div className="card-body">
                       <div className='table-responsive'>
                           <table
                               id="items"
                               className='table datatable'
                           >
                               <thead>
                               <tr>
                                   <th>Image</th>
                                   <th>Name</th>
                                   <th>Price</th>
                                   <th>Category</th>
                                   <th>Status</th>
                                   <th>Added Date</th>
                                   <th>Change Status</th>
                                   <th>Delete</th>
                                   <th>Details</th>
                                   <th>Added By</th>
                               </tr>
                               </thead>
                               <tbody>

                               </tbody>
                           </table>
                       </div>


                   </div>
               </div>}
       </>
    );
}

export default ManageItems;
