import React, {useEffect, useState} from 'react';
import store from "../../store";
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import {addCategory} from "../../store/common/actions";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import moment from "moment";
import {upload} from "../../store/utility/action";





const AddCategory = () => {
    const allCategories = useSelector((state) => state.common.categories)
    const [title, setTitle] = useState(null);
    const [slug, setSlug] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState(null);

    useEffect(() => {
        let _categories = [];
        allCategories.forEach((cat) => {
            if (!cat.deleted) {
                _categories.push({ value: cat._id, label: cat.title },)
            }
        });
        setParents(_categories);
    }, [allCategories])



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage(image);
        }
        const data = {
            title,
            slug,
            ...(parent && {parent: parent.value}),
            ...(imageUrl !== '' && {image: imageUrl,}),
        };
        await store.dispatch(addCategory(data));
        e.target.reset();
        setLoading(false);
    };

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Add Category</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Title"
                                       onChange={(e) => setTitle(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="slug" className="form-label">Slug <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="slug" name="slug" placeholder="Slug"
                                       onChange={(e) => setSlug(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                        </div>
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="type" className="form-label">Parent</label>
                            <Select
                                name="form-field-name"
                                options={parents}
                                onChange={setParent} />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default AddCategory;
