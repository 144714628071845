import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {addOutlet, getAllStores, getOutlet, updateOutlet} from "../../services/OutletService";
import Select from "react-select";
import {useParams} from "react-router";
import {getStore} from "../../services/StoreService";

const EditOutlet = () => {
    const params = useParams();
    const locations = useSelector((state) => state.common.locations);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [emails, setEmails] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [locality, setLocality] = useState(null);
    const [timings, setTimings] = useState({
        monday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        tuesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        wednesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        thursday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        friday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        saturday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        sunday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
    });


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...(phoneNumbers !== '' && {phoneNumbers: phoneNumbers.split(',')}),
            ...(emails !== '' && {emails: emails.split(',')}),
            address,
            postcode,
            timings,
            city: city.value,
            state: state.value,
            ...(locality && {locality: locality.value}),
            ...((latitude && longitude) && {coordinates: {latitude, longitude}})
        };
        await updateOutlet(params.outlet, data);
        setLoading(false);
    };

    useEffect(() => {
        let _states = [];
        let _cities = [];
        let _localities = [];
        locations.forEach((i) => {
            if (!i.deleted) {
                if (i.type === 'State') {
                    _states.push({value: i._id, label: i.title},);
                } else if (i.type === 'City') {
                    _cities.push({value: i._id, label: i.title},);
                } else {
                    _localities.push({value: i._id, label: i.title},);
                }
            }
        });
        setStates(_states);
        setCities(_cities);
        setLocalities(_localities);
    }, []);

    const loadDetails = async () => {
        const outlet = await getOutlet(params.outlet);
        if (outlet) {
            setAddress(outlet.address);
            setPostcode(outlet.postcode);
            setState({label: outlet.state.title, value: outlet.state._id});
            setCity({label: outlet.city.title, value: outlet.city._id});
            if (outlet.locality) {
                setLocality({label: outlet.locality.title, value: outlet.locality._id});
            }
            if (outlet.coordinates) {
                setLatitude(outlet.coordinates.latitude);
                setLongitude(outlet.coordinates.longitude);
            }
            setPhoneNumbers(outlet.phoneNumbers.join(','));
            setEmails(outlet.emails.join(','));
            if (outlet.timings) {
                setTimings(outlet.timings);
            }
        }
    }

    useEffect(() => {
        loadDetails();
    }, [params.outlet]);


    return (
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title">Edit Outlet</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="phone-number" className="form-label">Phone Numbers</label>
                                <input type="text" className="form-control" id="phone-number" name="phone-number"
                                       placeholder="Phone numbers (Separated by comma)" value={phoneNumbers}
                                       onChange={(e) => setPhoneNumbers(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="emails" className="form-label">Emails</label>
                                <input type="text" className="form-control" id="emails" name="emails"
                                       placeholder="Emails (Separated by comma)" value={emails}
                                       onChange={(e) => setEmails(e.target.value)}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="address" className="form-label">Address <span
                                className='text-danger'>(required)</span></label>
                            <textarea className="form-control" id="address" rows="5" value={address}
                                      onChange={(e) => setAddress(e.target.value)} required={true}/>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="postcode" className="form-label">Postcode <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="postcode" name="postcode"
                                       placeholder="Postcode" value={postcode}
                                       onChange={(e) => setPostcode(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="latitude" className="form-label">Latitude</label>
                                <input type="number" className="form-control" id="latitude" name="latitude"
                                       placeholder="Latitude" value={latitude}
                                       onChange={(e) => setLatitude(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="longitude" className="form-label">Longitude</label>
                                <input type="number" className="form-control" id="longitude" name="longitude"
                                       placeholder="Longitude" value={longitude}
                                       onChange={(e) => setLongitude(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="state" className="form-label">State <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={states}
                                onChange={setState}
                                value={state}
                                required={true}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="city" className="form-label">City <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={cities}
                                onChange={setCity}
                                value={city}
                                required={true}/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-4">
                            <label htmlFor="locality" className="form-label">Locality</label>
                            <Select
                                name="form-field-name"
                                options={localities}
                                value={locality}
                                onChange={setLocality}/>
                        </div>

                        <div className="mb-3 mt-3 col-12">
                            <label htmlFor="type" className="form-label">Timings</label>
                            <div className='table-responsive'>
                                <table
                                    id="new-timings"
                                    className='table datatable'
                                >
                                    <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Closed</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Monday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.monday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           monday: {...timings.monday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.monday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           monday: {...timings.monday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.monday.closed} onChange={(e) => {
                                            console.log(e.target.checked);
                                            setTimings({
                                                ...timings,
                                                monday: {...timings.monday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Tuesday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.tuesday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           tuesday: {...timings.tuesday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.tuesday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           tuesday: {...timings.tuesday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.tuesday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                tuesday: {...timings.tuesday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Wednesday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.wednesday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           wednesday: {...timings.wednesday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.wednesday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           wednesday: {...timings.wednesday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.wednesday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                wednesday: {...timings.wednesday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Thursday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.thursday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           thursday: {...timings.thursday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.thursday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           thursday: {...timings.thursday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.thursday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                thursday: {...timings.thursday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Friday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.friday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           friday: {...timings.friday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.friday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           friday: {...timings.friday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.friday.closed} onChange={(e) => {

                                            setTimings({
                                                ...timings,
                                                friday: {...timings.friday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Saturday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.saturday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           saturday: {...timings.saturday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.saturday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           saturday: {...timings.saturday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.saturday.closed} onChange={(e) => {

                                            setTimings({
                                                ...timings,
                                                saturday: {...timings.saturday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td>Sunday</td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="Start time" value={timings.sunday.start}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           sunday: {...timings.sunday, start: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-3">
                                                <input type="time" className="form-control"
                                                       placeholder="End time" value={timings.sunday.end}
                                                       onChange={(e) => setTimings({
                                                           ...timings,
                                                           sunday: {...timings.sunday, end: e.target.value}
                                                       })}/>
                                            </div>
                                        </td>
                                        <td><input className="form-check-input" type="checkbox" value="closed"
                                                   checked={timings.sunday.closed} onChange={(e) => {
                                            setTimings({
                                                ...timings,
                                                sunday: {...timings.sunday, closed: e.target.checked}
                                            })
                                        }}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                    </button>

                </form>

            </div>
        </div>
    );
}

export default EditOutlet;
