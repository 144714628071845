import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from "axios";
import 'datatables.net-bs4';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import baseConfig from "../../Config";
import {updateStore} from "../../services/StoreService";
import {updateBlog} from "../../services/BlogService";
import {updateCustomSection} from "../../services/CustomSectionService";


const ManageStores = () => {
    const navigate = useNavigate();
    const location = useLocation();

    function getStoreList() {
        try {
            $('#stores').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#stores').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 10,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<p class="badge bg-success-light">Paid</p>`;
                        } else {
                            return `<p class="badge bg-primary-light">Free</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        if (row[5] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateStore(rowData[7], {
                                status: rowData[5] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getStoreList();
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<input type="checkbox" checked />`;
                        } else {
                            return `<input type="checkbox" />`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            console.log(cellData, rowData[8]);
                            const data = {
                                ssPartner: !cellData
                            }
                            await updateStore(rowData[8], data);
                        })
                    },
                    "targets": 9
                },
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<input type="checkbox" checked />`;
                        } else {
                            return `<input type="checkbox" />`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            console.log(cellData, rowData[8]);
                            const data = {
                                freeDiscountAvailable: !cellData
                            }
                            await updateStore(rowData[8], data);
                        })
                    },
                    "targets": 10
                },
                {

                    "render": function (data, type, row) {
                        return `<input type="number" class="form-control" value="${data}" id="store-${row[8]}" />`
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(document).ready(function() {
                            $(`#store-${rowData[8]}`).change(async function(e) {
                                const data = {
                                    freeDiscountPercentage: parseInt(e.target.value)
                                }
                                console.log(data);
                                await updateStore(rowData[8], data);
                            });
                        });
                    },
                    "targets": 11
                },
                {

                    "render": function (data, type, row) {
                        return `<input type="number" class="form-control" value="${data}" id="store-${row[8]}" />`
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(document).ready(function() {
                            $(`#store-${rowData[8]}`).change(async function(e) {
                                const data = {
                                    discountPercentage: parseInt(e.target.value)
                                }
                                await updateStore(rowData[8], data);
                            });
                        });
                    },
                    "targets": 12
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateStore(rowData[13], {
                                deleted: true
                            })
                            getStoreList();
                        })
                    },
                    "targets": 13
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/store/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    useEffect(() => {
        getStoreList();
    }, []);

    return (
        <div>

            <div class="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Stores</h5>
                </div>
                <div class="card-body">
                    <div className='table-responsive'>
                        <table
                            id="stores"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Logo</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Category</th>
                                <th>Plan</th>
                                <th>Status</th>
                                <th>Added Date</th>
                                <th>Change Status</th>
                                <th>Details</th>
                                <th>SuperSaver Partner</th>
                                <th>Free Discount Available</th>
                                <th>Free Discount Percentage</th>
                                <th>Card Discount %</th>
                                <th>Delete</th>
                                <th>Added By</th>
                            </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageStores;
