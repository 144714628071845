import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store from "../store";
import {logout} from "../store/auth/actions";
import {useNavigate} from "react-router";

const Header = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth);

    return <>
        <div className="header header-one">

            <div className="header-left header-left-one">
                <Link to={'/'} className="logo">
                    <img src="/assets/img/logo.png" alt="Logo" />
                </Link>
                <Link to={'/'} className="white-logo">
                    <img src="/assets/img/logo.png" alt="Logo" />
                </Link>
                <Link to={'/'} className="logo logo-small">
                    <img src="/assets/img/logo-small-new.png" alt="Logo" width="30" height="30" />
                </Link>
            </div>


            <a href="javascript:void(0);" id="toggle_btn">
                <i className="fas fa-bars" />
            </a>


            <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fas fa-search" /></button>
                </form>
            </div>


            <a className="mobile_btn" id="mobile_btn">
                <i className="fas fa-bars" />
            </a>


            <ul className="nav nav-tabs user-menu">


                <li className="nav-item dropdown has-arrow main-drop">
                    <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <span className="user-img">
                            <img src={`${user.profilePic ? user.profilePic : `https://ui-avatars.com/api/?background=1b6ca8&color=fff&name=${user.name}&size=40&rounded=true`}`} alt="" />
                            <span className="status online" />
                        </span>
                        <span>{user.name}</span>
                    </a>
                    <div className="dropdown-menu">
                        <Link className="dropdown-item" to={'/edit-profile'}><i data-feather="user" className="me-1" />
                            Edit Profile</Link>
                        <a className="dropdown-item" href="#" onClick={() => {
                            store.dispatch(logout());
                            navigate('/');
                        }}><i data-feather="log-out" className="me-1" />
                            Logout</a>
                    </div>
                </li>

            </ul>

        </div>
    </>;
}

export default Header;
