import React, {useState} from 'react';
import store from "../../store";
import {addConfig} from "../../store/common/actions";
import {addUser} from "../../services/UserService";
import toast from "react-hot-toast";

const AddConfig = () => {
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            name,
            phoneNumber
        };
        const res = await addUser(data);
        if (res) {
            toast.success("User added successfully", {});
        }
        e.target.reset();
        setLoading(false);
    };

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Add User</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Phone Number <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="phone-number" name="phone-number" placeholder="Phone Number"
                                       onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Name</label>
                                <input type="text" className="form-control" id="name" name="name" placeholder="Name"
                                       onChange={(e) => setName(e.target.value)} value={name}/>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default AddConfig;
