import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addVoucher = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/voucher`,
                body,
                config
            );
            toast.success("Voucher added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const updateVoucher = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.patch(
                `${baseConfig.apiBaseUrl}/voucher/${id}`,
                body,
                config
            );
            toast.success("Voucher updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getVoucher = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/voucher/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}
