import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from "axios";
import 'datatables.net-bs4';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import baseConfig from "../../Config";
import {updatePartnerRequestStatus, updateStore} from "../../services/StoreService";
import {updateBlog} from "../../services/BlogService";
import {updateCustomSection} from "../../services/CustomSectionService";


const ManagePartnerRequests = () => {
    const navigate = useNavigate();
    const location = useLocation();

    function getData() {
        try {
            $('#stores').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#stores').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 10,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data === 'Pending') {
                            return `<p class="badge bg-primary-light">Pending</p>`;
                        } else {
                            return `<p class="badge bg-success-light">Processed</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        if (row[5] === 'Pending') {
                            return `<button class="btn btn-success">Move to Processed</button>`;
                        } else {
                            return `<button class="btn btn-primary">Move to Pending</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updatePartnerRequestStatus(rowData[7], rowData[5] === 'Pending' ? 'Processed' : 'Pending')
                            getData();
                        })
                    },
                    "targets": 7
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/store/partner-requests`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    useEffect(() => {
        getData();
    }, []);

    return (
        <div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Partner Requests</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="stores"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Location</th>
                                <th>About</th>
                                <th>Status</th>
                                <th>Added Date</th>
                                <th>Change Status</th>
                            </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManagePartnerRequests;
