import React, {useEffect, useState} from 'react';
import {addOutlet, getAllStores, updateOutlet} from '../../services/OutletService';
import Select from "react-select";
import {useSelector} from "react-redux";
import $ from "jquery";
import {updateStore} from "../../services/StoreService";
import baseConfig from "../../Config";
import {useNavigate} from "react-router";
import {useSearchParams, useLocation} from "react-router-dom";
import {addCoupon, updateCoupon} from "../../services/CouponService";
import {upload} from "../../store/utility/action";


const ManageCoupons = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [stores, setStores] = useState([]);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [code, setCode] = useState(null);
    const [shortDescription, setShortDescription] = useState('');
    const [details, setDetails] = useState('');
    const [link, setLink] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadImage(image);
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const data = {
            store,
            title,
            code,
            link,
            shortDescription,
            details,
            expiryDate,
            category: category.value,
            tags: _tags,
            ...(imageUrl !== '' && {image: imageUrl,}),
        };
        const res = await addCoupon(data);
        if (res) {
            getCouponList();
            e.target.reset();
            setTitle('');
            setCode('');
            setShortDescription('');
            setDetails('');
            setLink('');
            setExpiryDate('');
            setCategory('');
            setTags([]);
        }
        setLoading(false);
    };

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        loadAllStores();
    }, []);



    useEffect(() => {
        getCouponList();
        if (store !== null) {
            setSearchParams({["store"]: store})
        }
    }, [store]);

    useEffect(() => {
        if (store == null && stores.length > 0) {
            const _store = searchParams.get('store');
            if (_store) {
                setStore(_store);
                stores.forEach((i) => {
                    if (i.value === _store) {
                        setSelectedStoreOption(i);
                    }
                });
            }
        }
    }, [searchParams, stores]);


    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])


    function getCouponList() {
        try {
            $('#coupons').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#coupons').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        if (row[5] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateCoupon(rowData[7], {
                                status: rowData[5] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getCouponList();
                        })
                    },
                    "targets": 7
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateCoupon(rowData[8], {
                                deleted: true
                            })
                            getCouponList();
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 9
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/coupon/list/${store}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }


    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Select Store</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Store <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={stores}
                                onChange={(e) => {
                                    setStore(e.value);
                                    setSelectedStoreOption(e);
                                }}
                                value={selectedStoreOption}
                                required={true}/>
                        </div>
                    </div>
                </div>
            </div>

            {store && <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Add Coupon</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title"
                                           placeholder="Title" value={title}
                                           onChange={(e) => setTitle(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="code" className="form-label">Coupon Code</label>
                                    <input type="text" className="form-control" id="code" name="code"
                                           placeholder="Coupon Code" value={code}
                                           onChange={(e) => setCode(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Image</label>
                                    <input type="file" className="form-control" id="image" name="image"
                                           placeholder="Image"
                                           onChange={(e) => setImage(e.target.files[0])}/>
                                </div>
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="short-description" className="form-label">Short Description</label>
                                <textarea className="form-control" id="short-description" rows="2" value={shortDescription}
                                          onChange={(e) => setShortDescription(e.target.value)}/>
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="details" className="form-label">Details</label>
                                <textarea className="form-control" id="details" rows="8" value={details}
                                          onChange={(e) => setDetails(e.target.value)}/>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="link" className="form-label">Link</label>
                                    <input type="text" className="form-control" id="link" name="link"
                                           placeholder="Link" value={link}
                                           onChange={(e) => setLink(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="expiry-date" className="form-label">Expiry Date</label>
                                    <input type="date" className="form-control" id="expiry-date" name="expiry-date"
                                           placeholder="Expiry Date" value={expiryDate}
                                           onChange={(e) => setExpiryDate(e.target.value)}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Category</label>
                                <Select
                                    name="form-field-name"
                                    options={categoryOptions}
                                    value={category}
                                    onChange={setCategory} />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="type" className="form-label">Tags</label>
                                <Select
                                    name="form-field-name"
                                    options={tagOptions}
                                    isMulti={true}
                                    value={tags}
                                    onChange={setTags} />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                        </button>

                    </form>

                </div>
            </div>}
            {store &&
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Manage Coupons</h5>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table
                                id="coupons"
                                className='table datatable'
                            >
                                <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Code</th>
                                    <th>Expiry Date</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Added Date</th>
                                    <th>Change Status</th>
                                    <th>Delete</th>
                                    <th>Details</th>
                                    <th>Added By</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>}
        </>
    );
}

export default ManageCoupons;
