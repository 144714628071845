import {useState} from "react";
import {Link} from "react-router-dom";
import {login} from "../../store/auth/actions";
import store from "../../store";
import toast from "react-hot-toast";


const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        store.dispatch(login(username, password));
    }

    return <div className="main-wrapper login-body">
        <div className="login-wrapper">
            <div className="container">
                <img className="img-fluid logo-dark mb-2" src="assets/img/bb_logo.png" alt="Logo" />
                    <div className="loginbox">
                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Login</h1>
                                <p className="account-subtitle">Access to our dashboard</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="form-control-label">Email Address/Username</label>
                                        <input type="text" className="form-control" onChange={(e) => setUsername(e.target.value)}  required={true} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">Password</label>
                                        <div className="pass-group">
                                            <input type={showPassword ? "text" : "password"} className="form-control pass-input" onChange={(e) => setPassword(e.target.value)}  required={true} />
                                                <span className={`toggle-password ${showPassword ? "fas fa-eye-slash" : "fas fa-eye"}`} onClick={() => togglePassword()}/>
                                        </div>
                                    </div>
                                    <button className="btn btn-lg btn-block btn-primary w-100" type="submit">Login
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>;
}

export default Login;
