import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addStore = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/store`,
                body,
                config
            );
            toast.success("Store added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}


export const updateStore = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/store/${id}`,
                body,
                config
            );
            toast.success("Store updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getStore = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/store/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const buyPremium = async (store, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/store/${store}/pro`,
                body,
                config
            );
            toast.success("Premium plan added successfully for the store", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const getPremium = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/store/${id}/pro`,
                config
            );
            return res.data;
        } catch (err) {
            return false;
        }
    }
}

export const removePremium = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            await axios.delete(
                `${baseConfig.apiBaseUrl}/store/${id}/pro`,
                config
            );
            toast.success("Premium plan removed successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}


export const getAllStoreOptions = async (page, limit) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/store/options/all?page=${page}&limit=${limit}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const checkStoreNameExists = async (name) => {
    let token = localStorage.getItem('token')
    if (token) {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                },
            };
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/store/name-exists/${name}`, config
            );
            return false;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return true;
        }
    }
}

export const updateTransactionStatus = async (id, status) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.patch(
                `${baseConfig.apiBaseUrl}/store/transactions/${id}/${status}`,
                {},
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}

export const updatePartnerRequestStatus = async (id, status) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.patch(
                `${baseConfig.apiBaseUrl}/store/partner-requests/${id}/${status}`,
                {},
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}
