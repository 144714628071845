import { useLocation, useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import baseConfig from "../../Config";
import { getAllStores } from "../../services/OutletService";
import Select from "react-select";

const ManageOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [store, setStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [selectedStoreOption, setSelectedStoreOption] = useState(null);

  const loadAllStores = async () => {
    let _loadedStores = await getAllStores();
    let _stores = [];
    for (const store of _loadedStores) {
      _stores.push({ label: store.name, value: store._id });
    }
    setStores(_stores);
  };

  useEffect(() => {
    loadAllStores();
  }, []);

  useEffect(() => {
    getOrders();
  }, [store]);

  const getOrders = () => {
    try {
      $("#orders").DataTable().destroy();
    } catch (e) {
      console.log(e);
    }
    let token = localStorage.getItem("token");
    $("#orders").DataTable({
      lengthMenu: [10, 20, 50, 100, 500, 1000],
      pageLength: 100,
      processing: true,
      serverSide: true,
      columnDefs: [
        {
          render: function (data, type, row) {
            return `<p class="text-primary">#${data}</p>`;
          },
          createdCell: (td, cellData, rowData, row, col) => {},
          targets: 0,
        },
        {
          render: function (data, type, row) {
            return `<p class="text-danger">₹ ${data}</p>`;
          },
          createdCell: (td, cellData, rowData, row, col) => {},
          targets: 4,
        },
        {
          render: function (data, type, row) {
            if (data === "No") {
              return ``;
            } else if (data === "Yes") {
              return `<p class="badge bg-success-light">Yes</p>`;
            }
            return ``;
          },
          createdCell: (td, cellData, rowData, row, col) => {},
          targets: 5,
        },
        {
          render: function (data, type, row) {
            return `<button class="btn btn-primary">Details</button>`;
          },
          createdCell: (td, cellData, rowData, row, col) => {
            $(td).click((e) => {
              navigate(`/orders/detail/${cellData}`);
            });
          },
          targets: 7,
        },
      ],
      ajax: {
        url: `${baseConfig.apiBaseUrl}/user/orders/all`,
        type: "POST",
        contentType: "application/json",
        headers: {
          Authorization: token,
        },
        data: function (d) {
          return JSON.stringify({
            ...d,
            ...(store && { store: store }),
          });
        },
      },
    });
  };

  return (
    <div>
      <div className="card mb-3">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">Select Store</h5>
          {/* Reset button */}
          <button
            className="btn btn-secondary"
            onClick={() => {
              setSelectedStoreOption(null);
              setStore(null);
            }}
          >
            Reset
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="mb-3 col-12">
              <label htmlFor="type" className="form-label">
                Store <span className="text-danger">(required)</span>
              </label>
              <Select
                name="form-field-name"
                options={stores}
                onChange={(e) => {
                  setStore(e.value);
                  setSelectedStoreOption(e);
                }}
                value={selectedStoreOption}
                required={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-5">
        <div className="card-header">
          <h5 className="card-title">Orders</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table id="orders" className="table datatable">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Store</th>
                  <th>User</th>
                  <th>Item</th>
                  <th>Total Amount</th>
                  <th>Free</th>
                  <th>Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageOrders;
