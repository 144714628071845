import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import { useSelector } from "react-redux";
import { addBlog } from "../../services/BlogService";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { getAllStores } from "../../services/OutletService";
import { upload } from "../../store/utility/action";



const AddBlog = () => {
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [title, setTitle] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [content, setContent] = useState("");
    const [store, setStore] = useState({ label: "", value: null });
    const [category, setCategory] = useState({ label: "", value: null });
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [storeOptions, setStoreOptions] = useState([]);

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const s of _loadedStores) {
            _stores.push({ label: s.name, value: s._id });
        }
        setStoreOptions(_stores);
    }


    useEffect(() => {
        loadAllStores();
    }, []);


    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({ label: cat.title, value: cat._id });
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        let videoUrl = "";
        if (image !== null) {
            imageUrl = await uploadFile(image);
        }
        if (video !== null) {
            videoUrl = await uploadFile(video);
        }
        const data = {
            title,
            content,
            shortDescription,
            slug,
            ...(store.value !== null && { store: store.value }),
            ...(category.value !== null && { category: category.value }),
            image: imageUrl,
            video: videoUrl,
        };
        const res = await addBlog(data);
        if (res) {
            e.target.reset();
        }
        setLoading(false);

    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Add Blog</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Title"
                                        onChange={(e) => setTitle(e.target.value)} required={true} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Short Description <span
                                        className='text-danger'>(required)</span></label>
                                    <textarea className="form-control" onChange={(e) => setShortDescription(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="slug" className="form-label">Slug <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="slug" name="slug" placeholder="Slug"
                                        onChange={(e) => setSlug(e.target.value)} required={true} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="store" className="form-label">Category</label>
                                    <Select
                                        name="form-field-name"
                                        options={categoryOptions}
                                        onChange={setCategory}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Image <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="file" className="form-control" accept="image/*" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} required={true} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="video" className="form-label">Video</label>
                                    <input type="file" className="form-control" accept="video/*" id="video" name="video" onChange={(e) => setVideo(e.target.files[0])} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="store" className="form-label">Store</label>
                                    <Select
                                        name="form-field-name"
                                        options={storeOptions}
                                        onChange={setStore}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Content <span
                                className='text-danger'>(required)</span></label>
                            <ReactQuill className="mb-3" theme="snow" value={content} onChange={setContent} required={true} />
                        </div>
                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status" />} Submit</button>

                    </form>

                </div>
            </div>
        </>);
}

export default AddBlog;


