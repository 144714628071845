import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addBlog = async (data) => {
    let token = localStorage.getItem("token");
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(`${baseConfig.apiBaseUrl}/blog`, body, config);
            toast.success("Blog added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
};

export const updateBlog = async (id, data) => {
    let token = localStorage.getItem("token");
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.patch(`${baseConfig.apiBaseUrl}/blog/${id}`, body, config);
            toast.success("Blog updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
};

export const getBlog = async (id) => {
    let token = localStorage.getItem("token");
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/blog/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
};
