import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAllOutlets, getAllStores} from "../../services/OutletService";
import Select from "react-select";
import {addItem} from "../../services/ItemService";
import {addVoucher, updateVoucher} from "../../services/VoucherService";
import $ from "jquery";
import {updateOffer} from "../../services/OfferService";
import baseConfig from "../../Config";
import {upload} from "../../store/utility/action";
import {updateDeal} from "../../services/DealService";

const ManageVouchers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [store, setStore] = useState(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState(null);
    const allCategories = useSelector((state) => state.common.categories)
    const [categoryOptions, setCategoryOptions] = useState([]);
    const allTags = useSelector((state) => state.common.tags)
    const [tagOptions, setTagOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [title, setTitle] = useState('');
    const [originalPrice, setOriginalPrice] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [expiryDate, setExpiryDate] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);
    const [image, setImage] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [timings, setTimings] = useState({
        monday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        tuesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        wednesday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        thursday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        friday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        saturday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
        sunday: {
            start: '09:00',
            end: '21:00',
            closed: false,
        },
    });

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({label: store.name, value: store._id});
        }
        setStores(_stores);
    }

    useEffect(() => {
        loadAllStores();
    }, []);

    useEffect(() => {
        getVoucherList();
        if (store !== null) {
            setSearchParams({["store"]: store})
        }
    }, [store]);

    useEffect(() => {
        if (store == null && stores.length > 0) {
            const _store = searchParams.get('store');
            if (_store) {
                setStore(_store);
                stores.forEach((i) => {
                    if (i.value === _store) {
                        setSelectedStoreOption(i);
                    }
                });
            }
        }
    }, [searchParams, stores]);


    useEffect(() => {
        let _categories = [];
        for (const cat of allCategories) {
            _categories.push({label: cat.title, value: cat._id});
        }
        setCategoryOptions(_categories);
    }, [allCategories])

    useEffect(() => {
        let _tags = [];
        for (const tag of allTags) {
            _tags.push({label: tag.title, value: tag._id});
        }
        setTagOptions(_tags);
    }, [allTags])

    const loadAllOutlets = async () => {
        let _loadedOutlets = await getAllOutlets(store);
        let _outlets = [];
        for (const outlet of _loadedOutlets) {
            _outlets.push({
                label: `${outlet.state != null ? outlet.state.title : ''}/${outlet.city != null ? outlet.city.title : ''}/${outlet.locality != null ? outlet.locality.title : ''}`,
                value: outlet._id
            });
        }
        setOutletOptions(_outlets);
    }

    useEffect(() => {
        if (store !== null) {
            loadAllOutlets();
        }
    }, [store])

    function getVoucherList() {
        try {
            $('#vouchers').DataTable().destroy();
        } catch (e) {

        }
        let token = localStorage.getItem('token')
        $('#vouchers').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 100px; border-radius: 5px"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<input type="checkbox" checked />`;
                        } else {
                            return `<input type="checkbox" />`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            console.log(cellData, rowData[8]);
                            const data = {
                                memberOnly: !cellData
                            }
                            await updateVoucher(rowData[9], data);
                        })
                    },
                    "targets": 8
                },
                {

                    "render": function (data, type, row) {
                        if (row[5] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateVoucher(rowData[9], {
                                status: rowData[5] === 'Active' ? 'Inactive' : 'Active'
                            })
                            getVoucherList();
                        })
                    },
                    "targets": 9
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(async (e) => {
                            await updateVoucher(rowData[10], {
                                deleted: true
                            })
                            getVoucherList();
                        })
                    },
                    "targets": 10
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 11
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/voucher/list/${store}`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let imageUrl = "";
        if (image !== null) {
            imageUrl = await uploadFile(image);
        }
        const _tags = [];
        for (const t of tags) {
            _tags.push(t.value);
        }
        const _outlets = [];
        for (const o of outlets) {
            _outlets.push(o.value);
        }
        const data = {
            store,
            title,
            originalPrice,
            discountedPrice,
            expiryDate,
            timings,
            outlets: _outlets,
            category: category.value,
            tags: _tags,
            image: imageUrl,
            quantity,
        };
        const res = await addVoucher(data);
        if (res) {
            getVoucherList();
            e.target.reset();
            setTitle('');
            setOriginalPrice(0);
            setDiscountedPrice(0);
            setCategory('');
            setTags([]);
            setOutlets([]);
            setTimings({
                monday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                tuesday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                wednesday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                thursday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                friday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                saturday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
                sunday: {
                    start: '09:00',
                    end: '21:00',
                    closed: false,
                },
            });
            setExpiryDate('');
        }
        setLoading(false);
    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Select Store</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Store <span
                                className='text-danger'>(required)</span></label>
                            <Select
                                name="form-field-name"
                                options={stores}
                                onChange={(e) => {
                                    setStore(e.value);
                                    setSelectedStoreOption(e);
                                }}
                                value={selectedStoreOption}
                                required={true}/>
                        </div>
                    </div>
                </div>
            </div>

            {store && <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Add Voucher</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title"
                                           placeholder="Title" value={title}
                                           onChange={(e) => setTitle(e.target.value)} required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="expiry-date" className="form-label">Expiry Date</label>
                                    <input type="date" className="form-control" id="expiry-date" name="expiry-date"
                                           placeholder="Expiry Date" value={expiryDate}
                                           onChange={(e) => setExpiryDate(e.target.value)}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="image" className="form-label">Image</label>
                                <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="url" className="form-label">Quantity</label>
                                    <input type="number" className="form-control" id="quantity" name="quantity" value={quantity} placeholder="Quantity"
                                           onChange={(e) => setQuantity(parseInt(e.target.value))}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="original-price" className="form-label">Original Price <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="number" className="form-control" id="original-price"
                                           name="original-price"
                                           placeholder="Original Price" value={originalPrice}
                                           onChange={(e) => setOriginalPrice(parseFloat(e.target.value))}
                                           required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="discounted-price" className="form-label">Discounted Price <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="number" className="form-control" id="discounted-price"
                                           name="discounted-price"
                                           placeholder="Discounted Price" value={discountedPrice}
                                           onChange={(e) => setDiscountedPrice(parseFloat(e.target.value))}
                                           required={true}/>
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Category</label>
                                <Select
                                    name="category"
                                    options={categoryOptions}
                                    value={category}
                                    onChange={setCategory}/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label htmlFor="type" className="form-label">Tags</label>
                                <Select
                                    name="tags"
                                    options={tagOptions}
                                    isMulti={true}
                                    value={tags}
                                    onChange={setTags}/>
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="type" className="form-label">Outlets <i>(Leave empty to add item for all
                                    outlets)</i></label>
                                <Select
                                    name="outlets"
                                    options={outletOptions}
                                    isMulti={true}
                                    value={outlets}
                                    onChange={setOutlets}/>
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="type" className="form-label">Timings</label>
                                <div className='table-responsive'>
                                    <table
                                        id="timings"
                                        className='table datatable'
                                    >
                                        <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Closed</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Monday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.monday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               monday: {...timings.monday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.monday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               monday: {...timings.monday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.monday.closed} onChange={(e) => {
                                                console.log(e.target.checked);
                                                setTimings({
                                                    ...timings,
                                                    monday: {...timings.monday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Tuesday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.tuesday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               tuesday: {...timings.tuesday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.tuesday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               tuesday: {...timings.tuesday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.tuesday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    tuesday: {...timings.tuesday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Wednesday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.wednesday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               wednesday: {...timings.wednesday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.wednesday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               wednesday: {...timings.wednesday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.wednesday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    wednesday: {...timings.wednesday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Thursday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.thursday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               thursday: {...timings.thursday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.thursday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               thursday: {...timings.thursday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.thursday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    thursday: {...timings.thursday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.friday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               friday: {...timings.friday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.friday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               friday: {...timings.friday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.friday.closed} onChange={(e) => {

                                                setTimings({
                                                    ...timings,
                                                    friday: {...timings.friday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Saturday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.saturday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               saturday: {...timings.saturday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.saturday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               saturday: {...timings.saturday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.saturday.closed} onChange={(e) => {

                                                setTimings({
                                                    ...timings,
                                                    saturday: {...timings.saturday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        <tr>
                                            <td>Sunday</td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="Start time" value={timings.sunday.start}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               sunday: {...timings.sunday, start: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="mb-3">
                                                    <input type="time" className="form-control"
                                                           placeholder="End time" value={timings.sunday.end}
                                                           onChange={(e) => setTimings({
                                                               ...timings,
                                                               sunday: {...timings.sunday, end: e.target.value}
                                                           })}/>
                                                </div>
                                            </td>
                                            <td><input className="form-check-input" type="checkbox" value="closed"
                                                       checked={timings.sunday.closed} onChange={(e) => {
                                                setTimings({
                                                    ...timings,
                                                    sunday: {...timings.sunday, closed: e.target.checked}
                                                })
                                            }}/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit
                        </button>

                    </form>

                </div>
            </div>}
            {store &&
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Manage Vouchers</h5>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table
                                id="vouchers"
                                className='table datatable'
                            >
                                <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Discounted Price</th>
                                    <th>Expiry Date</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Added Date</th>
                                    <th>Quantity</th>
                                    <th>Member Only</th>
                                    <th>Change Status</th>
                                    <th>Delete</th>
                                    <th>Details</th>
                                    <th>Added By</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>}
        </>
    );
}

export default ManageVouchers;
