import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import "react-select-2/dist/css/react-select-2.css";
import { useSelector } from "react-redux";
import {
  addMember,
  generateRandomPassword,
  getTeamMemberDetails,
  updateTeamMember,
} from "../../services/TeamService";

const EditTeamMember = () => {
  const { member } = useParams();
  const allPermissions = useSelector((state) => state.common.permissions);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState([]);

  const getMemberDetails = async () => {
    const details = await getTeamMemberDetails(member);
    console.log(details);
    if (details) {
      const _permissions = [];
      setName(details.name);
      setEmail(details.email);
      for (let per of details.permissions) {
        _permissions.push({ value: per._id, label: per.title });
      }
      setPermissions(_permissions);
    }
  };

  useEffect(() => {
    getMemberDetails();
  }, [member, permissionOptions]);

  useEffect(() => {
    let _permissions = [];
    allPermissions.forEach((per) => {
      if (!per.deleted) {
        _permissions.push({ value: per._id, label: per.title });
      }
    });
    setPermissionOptions(_permissions);
  }, [allPermissions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const _permissions = [];
    permissions.forEach((per) => {
      _permissions.push(per.value);
    });
    const data = {
      name,
      email,
      ...(password && { password }),
      permissions: _permissions,
    };
    await updateTeamMember(member, data);
    setLoading(false);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Update Team Member</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name <span className="text-danger">(required)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email <span className="text-danger">(required)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              </div>
              <div className="mb-3 col-sm-12 col-md-12">
                <label htmlFor="type" className="form-label">
                  Permissions
                </label>
                <Select
                  value={permissions}
                  name="form-field-name"
                  options={permissionOptions}
                  isMulti={true}
                  onChange={setPermissions}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary me-2"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                />
              )}{" "}
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditTeamMember;
