import {
    loginSuccess,
    loginFailed,
    refreshTokenSuccess,
    refreshTokenFailed,
    logoutSuccess,
    profileUpdateFailed,
    profileUpdateSuccess
} from "./reducers";
import axios from "axios";
import baseConfig from "../../Config";


export const login = (username, password) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": '',
        },
    };

    const body = JSON.stringify({ username, password });
    try {
        let res = await axios.post(
            `${baseConfig.apiBaseUrl}/auth/login`,
            body,
            config
        );
        console.log(res.data);
        dispatch({
            type: loginSuccess,
            payload: res.data,
        });
    }  catch(err) {
        if (err.response) {
            dispatch({
                type: loginFailed,
                payload: err.response.data,
            });
        }

    }

}

export const refreshToken = () => async (dispatch) => {

    let token = localStorage.getItem('token');
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/auth/refresh-token`,
                config
            );
            dispatch({
                type: refreshTokenSuccess,
                payload: res.data,
            });
        } catch (err) {
            console.log(err);
        }

    }
}

export const updateProfile = (data) => async (dispatch) => {

    let token = localStorage.getItem('token');
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/auth/update-profile`,
                data,
                config
            );
            dispatch({
                type: profileUpdateSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: profileUpdateFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}

export const logout = () => async (dispatch) => {
    dispatch({
        type: logoutSuccess,
    });
}
