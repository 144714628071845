import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net';
import {useLocation, useNavigate} from "react-router";
import store from "../../store";
import {
    updateLocation
} from "../../store/common/actions";
import moment from "moment";

const ManageLocations = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const allItems = useSelector((state) => state.common.locations)
    const [items, setItems] = useState([]);

    useEffect(() => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            data: items,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        return `<p class="text-primary" style="cursor: pointer">${data[1]}</p>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData[0]}`);
                        })

                    },
                    "targets": 2
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Active') {
                            return `<p class="badge bg-success-light">Active</p>`;
                        } else {
                            return `<p class="badge bg-danger-light">Deactive</p>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 3
                },
                {

                    "render": function (data, type, row) {
                        if (row[3] === 'Active') {
                            return `<button class="btn btn-danger">Deactivate</button>`;
                        } else {
                            return `<button class="btn btn-success">Activate</button>`;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const category = allItems.find((i) => i._id === rowData[7]);
                            if (category) {
                                store.dispatch(updateLocation(rowData[7], {
                                    ...category,
                                    status: rowData[3] === 'Active' ? 'Inactive' : 'Active'
                                }));
                            }
                        })
                    },
                    "targets": 5
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-danger">Delete</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            const category = allItems.find((i) => i._id === rowData[7]);
                            if (category) {
                                store.dispatch(updateLocation(rowData[7], {
                                    ...category,
                                    deleted: true
                                }));
                            }
                        })
                    },
                    "targets": 6
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Edit</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/edit/${cellData}`);
                        })
                    },
                    "targets": 7
                },
            ]
        });
    }, [items]);

    useEffect(() => {
        let _items = [];
        allItems.forEach((cat) => {
            if (!cat.deleted) {
                _items.push([cat.title, cat.type, cat.parent ? [cat.parent._id, cat.parent.title] : ['', ''], cat.status, moment(cat.createdAt || "").format('MMMM Do YYYY'), cat._id, cat._id, cat._id, cat.addedBy != null ? cat.addedBy.name : ''])
            }
        });
        setItems(_items);
    }, [allItems])

    return (
        <div>

            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Manage Locations</h5>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table
                            id="table"
                            className='table datatable'
                        >
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Parent</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Change Status</th>
                                <th>Delete</th>
                                <th>Details</th>
                                <th>Added By</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ManageLocations;
