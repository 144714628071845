import {useLocation, useNavigate, useParams} from "react-router";
import React, {useEffect} from "react";
import $ from "jquery";
import baseConfig from "../../Config";
import {updateTeamMember} from "../../services/TeamService";

const ManageUsers = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        try {
            $('#table').DataTable().destroy();
        } catch (e) {
            console.log(e);
        }
        let token = localStorage.getItem('token')
        $('#table').DataTable({
            lengthMenu: [10, 20, 50, 100, 500, 1000],
            pageLength: 100,
            processing: true,
            serverSide: true,
            columnDefs: [
                {

                    "render": function (data, type, row) {
                        if (data) {
                            return `<div class="figure"><img src="${data}" style="width: 60px;height: 60px; border-radius: 100px; object-fit: cover;"></div>`;
                        }
                        return '<div></div>';
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 0
                },
                {

                    "render": function (data, type, row) {
                        if (data === 'Yes') {
                            return `<p class="badge text-black" style="background-color: gold">Gold Member</p>`;
                        } else {
                            return ``;
                        }
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {

                    },
                    "targets": 4
                },
                {

                    "render": function (data, type, row) {
                        return `<button class="btn btn-primary">Details</button>`;
                    },
                    "createdCell": (td, cellData, rowData, row, col) => {
                        $(td).click(e => {
                            navigate(`${location.pathname}/detail/${cellData}`);
                        })
                    },
                    "targets": 6
                },
            ],
            ajax: {
                url: `${baseConfig.apiBaseUrl}/user/list`,
                type: "POST",
                contentType: "application/json",
                headers: {
                    "Authorization": token,
                },
                data: function (d) {
                    return JSON.stringify(d);
                },
            }
        });
    }
    return <div>

        <div className="card mt-3">
            <div className="card-header">
                <h5 className="card-title">Manage Team Members</h5>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <table
                        id="table"
                        className='table datatable'
                    >
                        <thead>
                        <tr>
                            <th>Profile Pic</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Balance</th>
                            <th>Gold Membership</th>
                            <th>Join Date</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>;
}

export default ManageUsers;
