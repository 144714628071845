import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Select from "react-select";
import "react-select-2/dist/css/react-select-2.css";
import {useSelector} from "react-redux";
import {
    addMember,
    generateRandomPassword,
    getTeamMemberDetails,
    updateTeamMember,
} from "../../services/TeamService";
import {getUserOrderDetails} from "../../services/UserService";

const UserOrderDetails = () => {
    const {id} = useParams();
    const [orderId, setOrderId] = useState(null);
    const [store, setStore] = useState(null);
    const [user, setUser] = useState(null);
    const [total, setTotal] = useState(null);
    const [free, setFree] = useState(null);
    const [item, setItem] = useState({
        title: '',
        price: '0',
        type: '',
    });
    const [payment, setPayment] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDetails = async () => {
        const details = await getUserOrderDetails(id);
        console.log(details);
        if (details) {
            setOrderId(details.orderId);
            setStore(details.store != null ? details.store.name : '');
            setUser(details.user.name);
            setTotal(details.total);
            setFree(details.free ? 'Yes' : 'No');
            setItem(details.item);
            setPayment(details.payment);
        }
    };

    useEffect(() => {
        getDetails();
    }, [id]);


    return (
        <>
            <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Order Details</h5>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Order ID
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="order-id"
                                        name="order-id"
                                        placeholder="Order ID"
                                        value={orderId}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                                <div className="col-sm-12 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Store
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="store"
                                            name="store"
                                            placeholder="Store"
                                            value={store}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            User
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="user"
                                            name="user"
                                            placeholder="User"
                                            value={user}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Total
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="total"
                                            name="total"
                                            placeholder="Total"
                                            value={total}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Free
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="free"
                                            name="free"
                                            placeholder="Free"
                                            value={free}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                            </div>
                    </div>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Item Details</h5>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Item
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="item"
                                        name="item"
                                        placeholder="Item"
                                        value={item.title}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Price
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        value={item.price}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Type
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="type"
                                        name="type"
                                        placeholder="Type"
                                        value={item.type}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {payment != null && <div className="card mb-5">
                <div className="card-header">
                    <h5 className="card-title">Payment Details</h5>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Razorpay ID
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="item"
                                        name="item"
                                        placeholder="Item"
                                        value={payment.razorpay_order_id}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Razorpay Payment ID
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        value={payment.razorpay_payment_id}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default UserOrderDetails;
