import axios from "axios";
import baseConfig from "../../Config";
import {
    allCategoriesLoaded, allConfigsLoaded, allLocationsLoaded, allPermissionsLoaded, allTagsLoaded, categoryAddSuccess,
    categoryUpdateSuccess, configAddSuccess, configUpdateSuccess, locationAddSuccess, locationUpdateSuccess,
    operationFailed, tagAddSuccess, tagUpdateSuccess
} from "./reducers";

export const addCategory = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/category`,
                body,
                config
            );
            dispatch({
                type: categoryAddSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllCategories = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/category?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data.length !== 0) {
                    dispatch({
                        type: allCategoriesLoaded,
                        payload: res.data,
                    });
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
                page = 0;
            }
        }

    }
}

export const updateCategory = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/category/${id}`,
                body,
                config
            );
            dispatch({
                type: categoryUpdateSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const addTag = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/tag`,
                body,
                config
            );
            dispatch({
                type: tagAddSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const updateTag = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/tag/${id}`,
                body,
                config
            );
            dispatch({
                type: tagUpdateSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllTags = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/tag?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data.length !== 0) {
                    dispatch({
                        type: allTagsLoaded,
                        payload: res.data,
                    });
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
                page = 0;
            }
        }

    }
}

export const addConfig = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/config`,
                body,
                config
            );
            dispatch({
                type: configAddSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const updateConfig = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/config/${id}`,
                body,
                config
            );
            dispatch({
                type: configUpdateSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllConfigs = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/config?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data.length !== 0) {
                    dispatch({
                        type: allConfigsLoaded,
                        payload: res.data,
                    });
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
                page = 0;
            }
        }

    }
}

export const addLocation = (data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.post(
                `${baseConfig.apiBaseUrl}/location`,
                body,
                config
            );
            dispatch({
                type: locationAddSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const updateLocation = (id, data) => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            let res = await axios.patch(
                `${baseConfig.apiBaseUrl}/location/${id}`,
                body,
                config
            );
            dispatch({
                type: locationUpdateSuccess,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }
    }
}

export const getAllLocations = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        let page = 1;
        let limit = 100;
        while (page !== 0) {
            try {
                let res = await axios.get(
                    `${baseConfig.apiBaseUrl}/location?page=${page}&limit=${limit}`,
                    config
                );
                if (res.data.length !== 0) {
                    dispatch({
                        type: allLocationsLoaded,
                        payload: res.data,
                    });
                    page++;
                } else {
                    page = 0;
                }
            } catch (err) {
                if (err.response) {
                    dispatch({
                        type: operationFailed,
                        payload: err.response.data,
                    });
                }
                page = 0;
            }
        }

    }
}


export const getAllPermissions = () => async (dispatch) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };

        try {
            let res = await axios.get(
                `${baseConfig.apiBaseUrl}/permission/list`,
                config
            );
            dispatch({
                type: allPermissionsLoaded,
                payload: res.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: operationFailed,
                    payload: err.response.data,
                });
            }
        }

    }
}