import React, { useState, useEffect } from 'react';
import { getAllStores } from '../../services/OutletService';
import { useSelector } from "react-redux";
import Select from "react-select";
import { upload } from '../../store/utility/action';
import { publishCampaign } from '../../services/CampaignService';

const PublishCampaign = () => {
    const locations = useSelector((state) => state.common.locations);
    const [stores, setStores] = useState([]);
    const [cities, setCities] = useState([]);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [image, setImage] = useState(null);
    const [location, setLocation] = useState(null);
    const [store, setStore] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let _image = null;
        if (image) {
            _image = await uploadImage(image);
        }
        let data = {
            title,
            body,
            ...(image && { image: _image }),
            ...(location && { location: location.value }),
            ...(store && { store: store.value }),
        }
        const res = await publishCampaign(data);
        // if (res) {
        //     e.target.reset();
        //     setTitle("");
        //     setBody("");
        //     setImage(null);
        //     setLocation(null);
        //     setStore(null);
        // }
        setLoading(false);
    }

    const uploadImage = async (img) => {
        let formData = new FormData();
        formData.append("files", img);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }

    const loadAllStores = async () => {
        let _loadedStores = await getAllStores();
        let _stores = [];
        for (const store of _loadedStores) {
            _stores.push({ label: store.name, value: store._id });
        }
        setStores(_stores);
    }

    useEffect(() => {
        let _cities = [];
        locations.forEach((i) => {
            if (!i.deleted) {
               if (i.type === 'City') {
                    _cities.push({ value: i._id, label: i.title },);
                }
            }
        });
        setCities(_cities);
    }, [locations]);

    useEffect(() => {
        loadAllStores();
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Publish Campaign</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title <span
                                        className='text-danger'>(required)</span></label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="Title"
                                        onChange={(e) => setTitle(e.target.value)} required={true} />
                                </div>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-6">
                                <label htmlFor="image" className="form-label">Image</label>
                                <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Message <span
                                        className='text-danger'>(required)</span></label>
                                    <textarea className="form-control" id="body" name="body" placeholder="Message"
                                        onChange={(e) => setBody(e.target.value)} required={true} />
                                </div>
                            </div>
                            <div className="mb-3 col-6">
                                <label htmlFor="type" className="form-label">Location</label>
                                <Select
                                    name="location"
                                    options={cities}
                                    value={location}
                                    onChange={setLocation} />
                            </div>
                            <div className="mb-3 col-6">
                                <label htmlFor="type" className="form-label">Store</label>
                                <Select
                                    name="store"
                                    options={stores}
                                    value={store}
                                    onChange={setStore} />
                            </div>
                            
                        </div>

                        <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                            <span className="spinner-border spinner-border-sm me-2" role="status" />} Submit</button>

                    </form>

                </div>
            </div>
        </>
    );
}

export default PublishCampaign;