import FeatherIcon from "feather-icons-react";
import { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { all } from "axios";

const Sidebar = () => {
  const permissions = useSelector((state) => state.auth.permissions);
  const [allowedRoutes, setAllowedRoutes] = useState([]);

  useEffect(() => {
    const routes = [];
    permissions.forEach((permission) => {
      if (permission.route) {
        routes.push(permission.route);
      }
    });
    setAllowedRoutes(routes);
  }, [permissions]);

  const onMenuClick = (e) => {
    e.preventDefault();
    const $clickedElement = $(e.target);
    const $parentUl = $clickedElement.parents("ul:first");

    if (!$clickedElement.hasClass("subdrop")) {
      console.log("here");
      $("ul", $parentUl).slideUp(350);
      $("a", $clickedElement.parents("ul:first")).removeClass("subdrop");
      $clickedElement.next("ul").slideDown(350);
      $clickedElement.addClass("subdrop");
    } else {
      // Handle dropdown items
      $clickedElement.removeClass("subdrop");
      $clickedElement.next("ul").slideUp(350);
    }

  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>
            <li>
              <Link to="/">
                <FeatherIcon icon="home" />
                <span>Dashboard</span>
              </Link>
            </li>
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/team-members")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="user" className='me-3 mb-1' />Team {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/team-members/add">Add member</Link>
                  </li>
                  <li>
                    <Link to="/team-members">Members list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/categories")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="list" className='me-3 mb-1'  />Categories {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/categories/add">Add category</Link>
                  </li>
                  <li>
                    <Link to="/categories">Categories list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/tags")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="tag" className='me-3 mb-1'  /> Tags {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/tags/add">Add Tag</Link>
                  </li>
                  <li>
                    <Link to="/tags">Tags list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/configs")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="settings" className='me-3 mb-1'  />Configs {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/configs/add">Add config</Link>
                  </li>
                  <li>
                    <Link to="/configs">Config list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/locations")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="map" className='me-3 mb-1'   />Locations {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/locations/add">Add Location</Link>
                  </li>
                  <li>
                    <Link to="/locations">Locations list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/blogs")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="settings" className='me-3 mb-1'   />Blogs {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/blogs/add">Add blog</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog list</Link>
                  </li>
                  <li>
                    <Link to="/sliders/add/Blogs">Add Slider</Link>
                  </li>
                  <li>
                    <Link to="/sliders/Blogs">Manage Sliders</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/custom-section")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="home" className='me-3 mb-1'   />Custom Section Design {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/custom-section/add">Add section</Link>
                  </li>
                  <li>
                    <Link to="/custom-sections">Sections list</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/campaign")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="send" className='me-3 mb-1'   />Campaign {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/campaign/publish">Publish campaign</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/stores")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="home" className='me-3 mb-1'   />Store {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/stores/add">Add Store</Link>
                  </li>
                  <li>
                    <Link to="/stores">Stores list</Link>
                  </li>
                  <li>
                    <Link to="/stores/withdrawals">Manage Withdrawals</Link>
                  </li>
                  <li>
                    <Link to="/stores/premium">Manage Premium Stores</Link>
                    <Link to="/stores/partner-requests">Manage Partner Requests</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/outlets")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="command" className='me-3 mb-1'   />Outlets {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/outlets">Outlets By Store</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/items")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="shopping-bag" className='me-3 mb-1'   />Items {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/items">Items By Store</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/photos")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="camera" className='me-3 mb-1'   />Photos {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/photos">Photos By Store</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/coupons")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="percent" className='me-3 mb-1'   />Coupons {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/coupons">Coupons By Store</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
              allowedRoutes.includes("/deals")) && (
              <li className="submenu">
                <Link to="" onClick={(e) => onMenuClick(e)}>
                  <FeatherIcon icon="percent" className='me-3 mb-1'   />Deals {" "}
                  <span className="menu-arrow" />
                </Link>
                <ul>
                  <li>
                    <Link to="/deals">Deals By Store</Link>
                  </li>
                </ul>
              </li>
            )}
            {(allowedRoutes.includes("/") ||
                allowedRoutes.includes("/vouchers")) && (
                <li className="submenu">
                  <Link to="" onClick={(e) => onMenuClick(e)}>
                    <FeatherIcon icon="percent" className='me-3 mb-1'   />Vouchers {" "}
                    <span className="menu-arrow" />
                  </Link>
                  <ul>
                    <li>
                      <Link to="/vouchers">Vouchers By Store</Link>
                    </li>
                  </ul>
                </li>
            )}
            {(allowedRoutes.includes("/") ||
                allowedRoutes.includes("/users")) && (
                <li className="submenu">
                  <Link to="" onClick={(e) => onMenuClick(e)}>
                    <FeatherIcon icon="user" className='me-3 mb-1'   />Users {" "}
                    <span className="menu-arrow" />
                  </Link>
                  <ul>
                    <li>
                      <Link to="/users/add">Add User</Link>
                      <Link to="/users">Manage Users</Link>
                      <Link to="/users/withdrawals">Manage Withdrawals</Link>
                      <Link to="/orders">Manage Orders</Link>
                    </li>
                  </ul>
                </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
