import React, {useState} from 'react';
import store from "../../store";
import {addConfig} from "../../store/common/actions";

const AddConfig = () => {
    const [attribute, setAttribute] = useState("");
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            attribute,
            value
        };
        await store.dispatch(addConfig(data));
        e.target.reset();
        setLoading(false);
    };

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Add Config</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Attribute <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="attribute" name="attribute" placeholder="Attribute"
                                       onChange={(e) => setAttribute(e.target.value)} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Value <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="value" name="value" placeholder="Value"
                                       onChange={(e) => setValue(e.target.value)} required={true}/>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default AddConfig;
