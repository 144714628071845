import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const publishCampaign = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/campaign/publish`,
                body,
                config
            );
            toast.success("Campaign published successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}