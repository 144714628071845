import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AuthMiddleware from "./middleware/AuthMiddleware";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/authentication/Login";
import { Toaster } from "react-hot-toast";
import store from "./store";
import { refreshToken } from "./store/auth/actions";
import EditProfile from "./pages/authentication/EditProfile";
import AddCategory from "./pages/category/AddCategory";
import {
  getAllCategories,
  getAllConfigs,
  getAllLocations,
  getAllPermissions,
  getAllTags,
} from "./store/common/actions";
import CategoryList from "./pages/category/CategoryList";
import EditCategory from "./pages/category/EditCategory";
import AddTag from "./pages/tag/AddTag";
import TagList from "./pages/tag/TagList";
import EditTag from "./pages/tag/EditTag";
import AddConfig from "./pages/config/AddConfig";
import ConfigList from "./pages/config/ConfigList";
import EditConfig from "./pages/config/EditConfig";
import AddLocation from "./pages/location/AddLocation";
import LocationList from "./pages/location/LocationList";
import EditLocation from "./pages/location/EditLocation";
import AddSeller from "./pages/seller/AddSeller";
import ManageSellers from "./pages/seller/ManageSellers";
import EditSeller from "./pages/seller/EditSeller";
import ManageOutlets from "./pages/outlet/ManageOutlets";
import EditOutlet from "./pages/outlet/EditOutlet";
import ManageCoupons from "./pages/coupon/ManageCoupon";
import EditCoupon from "./pages/coupon/EditCoupon";
import ManageOffer from "./pages/offer/ManageOffer";
import EditOffer from "./pages/offer/EditOffer";
import ManageItems from "./pages/item/ManageItem";
import EditItem from "./pages/item/EditItem";
import ManagePhotos from "./pages/photo/ManagePhotos";
import ManageDeals from "./pages/deal/ManageDeals";
import EditDeal from "./pages/deal/EditDeal";
import ManageVouchers from "./pages/voucher/ManageVoucher";
import EditVoucher from "./pages/voucher/EditVoucher";
import ManagePremium from "./pages/seller/ManagePremium";
import ManageTimings from "./pages/timing/ManageTiming";
import EditTiming from "./pages/timing/EditTiming";
import AddBlog from "./pages/blog/AddBlog";
import ManageBlog from "./pages/blog/ManageBlog";
import EditBlog from "./pages/blog/EditBlog";
import ManageHome from "./pages/home/ManageContent";
import AddHomeContent from "./pages/home/AddContent";
import EditHomeContent from "./pages/home/EditContent";
import AddSlider from "./pages/slider/AddSlider";
import ManageSlider from "./pages/slider/ManageSlider";
import EditSlider from "./pages/slider/EditSlider";
import AddCustomSection from "./pages/custom-section/AddCustomSection";
import ManageCustomSection from "./pages/custom-section/ManageCustomSection";
import EditCustomSection from "./pages/custom-section/EditCustomSection";
import EditCustomSectionContent from "./pages/custom-section/EditCustomSectionContent";
import AddTeamMember from "./pages/team/AddTeamMember";
import ManageTeamMember from "./pages/team/ManageTeamMember";
import EditTeamMember from "./pages/team/EditTeamMember";
import ManageUsers from "./pages/user/ManageUsers";
import UserDetails from "./pages/user/UserDetails";
import ManageTransactions from "./pages/user/ManageTransactions";
import UserOrderDetail from "./pages/user/UserOrderDetail";
import ManageOrders from "./pages/user/ManageOrders";
import AddUser from "./pages/user/AddUser";
import ManageWithdrawals from "./pages/seller/ManageWithdrawals";
import PublishCampaign from "./pages/campaign/PublishCampaign";
import ManagePartnerRequests from "./pages/seller/ManagePartnerRequests";

function App() {
  const authToken = useSelector((state) => state.auth.token);
  const permissions = useSelector((state) => state.auth.permissions);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (authToken != null && !initialized) {
      store.dispatch(getAllCategories());
      store.dispatch(getAllTags());
      store.dispatch(getAllConfigs());
      store.dispatch(getAllLocations());
      store.dispatch(getAllPermissions());
      setInitialized(true);
    }
  }, [authToken]);

  useEffect(() => {
    store.dispatch(refreshToken());
  }, []);

  useEffect(() => {
    const routes = [];
    permissions.forEach((permission) => {
      if (permission.route) {
        routes.push(permission.route);
      }
    });
    setAllowedRoutes(routes);
  }, [permissions]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <AuthMiddleware>
              <Dashboard />
            </AuthMiddleware>
          }
        />
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/team-members")) && (
          <>
            <Route
              path="/team-members/add"
              element={
                <AuthMiddleware>
                  <AddTeamMember />
                </AuthMiddleware>
              }
            />
            <Route
              path="/team-members"
              element={
                <AuthMiddleware>
                  <ManageTeamMember />
                </AuthMiddleware>
              }
            />
            <Route
              path="/team-members/edit/:member"
              element={
                <AuthMiddleware>
                  <EditTeamMember />
                </AuthMiddleware>
              }
            />
          </>
        )}
        <Route
          path="/login"
          element={
            <AuthMiddleware>
              <Login />
            </AuthMiddleware>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <AuthMiddleware>
              <EditProfile />
            </AuthMiddleware>
          }
        />
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/categories")) && (
          <>
            <Route
              path="/categories/add"
              element={
                <AuthMiddleware>
                  <AddCategory />
                </AuthMiddleware>
              }
            />
            <Route
              path="/categories"
              element={
                <AuthMiddleware>
                  <CategoryList />
                </AuthMiddleware>
              }
            />
            <Route
              path="/categories/edit/:category"
              element={
                <AuthMiddleware>
                  <EditCategory />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/tags")) && (
          <>
            <Route
              path="/tags/add"
              element={
                <AuthMiddleware>
                  <AddTag />
                </AuthMiddleware>
              }
            />
            <Route
              path="/tags"
              element={
                <AuthMiddleware>
                  <TagList />
                </AuthMiddleware>
              }
            />
            <Route
              path="/tags/edit/:tag"
              element={
                <AuthMiddleware>
                  <EditTag />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/configs")) && (
          <>
            <Route
              path="/configs/add"
              element={
                <AuthMiddleware>
                  <AddConfig />
                </AuthMiddleware>
              }
            />
            <Route
              path="/configs"
              element={
                <AuthMiddleware>
                  <ConfigList />
                </AuthMiddleware>
              }
            />
            <Route
              path="/configs/edit/:config"
              element={
                <AuthMiddleware>
                  <EditConfig />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/locations")) && (
          <>
            <Route
              path="/locations/add"
              element={
                <AuthMiddleware>
                  <AddLocation />
                </AuthMiddleware>
              }
            />
            <Route
              path="/locations"
              element={
                <AuthMiddleware>
                  <LocationList />
                </AuthMiddleware>
              }
            />
            <Route
              path="/locations/edit/:location"
              element={
                <AuthMiddleware>
                  <EditLocation />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/stores")) && (
          <>
            <Route
              path="/stores/add"
              element={
                <AuthMiddleware>
                  <AddSeller />
                </AuthMiddleware>
              }
            />
            <Route
              path="/stores"
              element={
                <AuthMiddleware>
                  <ManageSellers />
                </AuthMiddleware>
              }
            />
            <Route
              path="/stores/edit/:store"
              element={
                <AuthMiddleware>
                  <EditSeller />
                </AuthMiddleware>
              }
            />
            <Route
                path="/stores/withdrawals"
                element={
                  <AuthMiddleware>
                    <ManageWithdrawals />
                  </AuthMiddleware>
                }
            />
            <Route
              path="/stores/premium"
              element={
                <AuthMiddleware>
                  <ManagePremium />
                </AuthMiddleware>
              }
            />
            <Route
                path="/stores/partner-requests"
                element={
                  <AuthMiddleware>
                    <ManagePartnerRequests />
                  </AuthMiddleware>
                }
            />
          </>
        )}

        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/outlets")) && (
          <>
            <Route
              path="/outlets"
              element={
                <AuthMiddleware>
                  <ManageOutlets />
                </AuthMiddleware>
              }
            />
            <Route
              path="/outlets/edit/:outlet"
              element={
                <AuthMiddleware>
                  <EditOutlet />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/coupons")) && (
          <>
            <Route
              path="/coupons"
              element={
                <AuthMiddleware>
                  <ManageCoupons />
                </AuthMiddleware>
              }
            />
            <Route
              path="/coupons/edit/:coupon"
              element={
                <AuthMiddleware>
                  <EditCoupon />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/deals")) && (
          <>
            <Route
              path="/deals"
              element={
                <AuthMiddleware>
                  <ManageDeals />
                </AuthMiddleware>
              }
            />
            <Route
              path="/deals/edit/:deal"
              element={
                <AuthMiddleware>
                  <EditDeal />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/vouchers")) && (
            <>
              <Route
                  path="/vouchers"
                  element={
                    <AuthMiddleware>
                      <ManageVouchers />
                    </AuthMiddleware>
                  }
              />
              <Route
                  path="/vouchers/edit/:voucher"
                  element={
                    <AuthMiddleware>
                      <EditVoucher />
                    </AuthMiddleware>
                  }
              />
            </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/items")) && (
          <>
            <Route
              path="/items"
              element={
                <AuthMiddleware>
                  <ManageItems />
                </AuthMiddleware>
              }
            />
            <Route
              path="/items/edit/:item"
              element={
                <AuthMiddleware>
                  <EditItem />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/photos")) && (
          <>
            <Route
              path="/photos"
              element={
                <AuthMiddleware>
                  <ManagePhotos />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") || allowedRoutes.includes("/blogs")) && (
          <>
            <Route
              path="/blogs/add"
              element={
                <AuthMiddleware>
                  <AddBlog />
                </AuthMiddleware>
              }
            />
            <Route
              path="/blogs"
              element={
                <AuthMiddleware>
                  <ManageBlog />
                </AuthMiddleware>
              }
            />
            <Route
              path="/blogs/edit/:blog"
              element={
                <AuthMiddleware>
                  <EditBlog />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/campaign")) && (
          <>
            <Route
              path="/campaign/publish"
              element={
                <AuthMiddleware>
                  <PublishCampaign />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/sliders")) && (
          <>
            <Route
              path="/sliders/add/:page"
              element={
                <AuthMiddleware>
                  <AddSlider />
                </AuthMiddleware>
              }
            />
            <Route
              path="/sliders/:page"
              element={
                <AuthMiddleware>
                  <ManageSlider />
                </AuthMiddleware>
              }
            />
            <Route
              path="/sliders/:page/edit/:slider"
              element={
                <AuthMiddleware>
                  <EditSlider />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
          allowedRoutes.includes("/custom-section")) && (
          <>
            <Route
              path="/custom-section/add"
              element={
                <AuthMiddleware>
                  <AddCustomSection />
                </AuthMiddleware>
              }
            />
            <Route
              path="/custom-sections"
              element={
                <AuthMiddleware>
                  <ManageCustomSection />
                </AuthMiddleware>
              }
            />
            <Route
              path="/custom-sections/edit/:section"
              element={
                <AuthMiddleware>
                  <EditCustomSection />
                </AuthMiddleware>
              }
            />
            <Route
              path="/custom-sections/edit/:section/edit/:content"
              element={
                <AuthMiddleware>
                  <EditCustomSectionContent />
                </AuthMiddleware>
              }
            />
          </>
        )}
        {(allowedRoutes.includes("/") ||
            allowedRoutes.includes("/users")) && (
            <>
              <Route
                  path="/users/add"
                  element={
                    <AuthMiddleware>
                      <AddUser />
                    </AuthMiddleware>
                  }
              />
              <Route
                  path="/users"
                  element={
                    <AuthMiddleware>
                      <ManageUsers />
                    </AuthMiddleware>
                  }
              />
              <Route
                  path="/users/detail/:id"
                  element={
                    <AuthMiddleware>
                      <UserDetails />
                    </AuthMiddleware>
                  }
              />
              <Route
                  path="/users/withdrawals"
                  element={
                    <AuthMiddleware>
                      <ManageTransactions />
                    </AuthMiddleware>
                  }
              />
            </>
        )}
        {(allowedRoutes.includes("/") ||
            allowedRoutes.includes("/orders")) && (
            <>
              <Route
                  path="/orders"
                  element={
                    <AuthMiddleware>
                      <ManageOrders />
                    </AuthMiddleware>
                  }
              />

              <Route
                  path="/orders/detail/:id"
                  element={
                    <AuthMiddleware>
                      <UserOrderDetail />
                    </AuthMiddleware>
                  }
              />

            </>
        )}
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
