import axios from "axios";
import baseConfig from "../Config";
import toast from "react-hot-toast";

export const addCustomSection = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/custom-section`,
                body,
                config
            );
            toast.success("Custom section added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const getCustomSectionDetails = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/custom-section/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const updateCustomSection = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.patch(
                `${baseConfig.apiBaseUrl}/custom-section/${id}`,
                body,
                config
            );
            toast.success("Custom section updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const addCustomSectionContent = async (data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.post(
                `${baseConfig.apiBaseUrl}/custom-section/content`,
                body,
                config
            );
            toast.success("Custom section content added successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const updateCustomSectionContent = async (id, data) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        const body = JSON.stringify(data);
        try {
            await axios.patch(
                `${baseConfig.apiBaseUrl}/custom-section/content/${id}`,
                body,
                config
            );
            toast.success("Custom section content updated successfully", {});
            return true;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const getCustomSectionContentDetails = async (id) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/custom-section/content/${id}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
                return false;
            }
        }
    }
}

export const getAllCategoryOptions = async (page, limit) => {
    let token = localStorage.getItem('token')
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        };
        try {
            const res = await axios.get(
                `${baseConfig.apiBaseUrl}/category/options/all?page=${page}&limit=${limit}`,
                config
            );
            return res.data;
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {});
            }
            return false;
        }
    }
}
