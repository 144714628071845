import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {upload} from "../../store/utility/action";
import {addSlider, getSlider, updateSlider} from "../../services/SliderService";





const EditSlider = () => {
    const {page, slider} = useParams();
    const allLocations = useSelector((state) => state.common.locations)
    const [url, setUrl] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        let _locations = [];
        allLocations.forEach((loc) => {
            if (!loc.deleted) {
                _locations.push({ value: loc._id, label: loc.title },)
            }
        });
        setLocationOptions(_locations);
    }, [allLocations])

    const getSliderDetail = async () => {
        const sliderDetails = await getSlider(slider);
        console.log(sliderDetails);
        if (sliderDetails) {
            const _locations = [];
            setImageUrl(sliderDetails.image);
            setUrl(sliderDetails.url);
            for (let loc of sliderDetails.locations) {
               _locations.push({label: loc.title, value: loc._id});
            }
            setLocations(_locations);
        }
    }

    useEffect(() => {
        getSliderDetail();
    }, [slider, locationOptions]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let _imageUrl = imageUrl;
        if (image !== null) {
            _imageUrl = await uploadFile(image);
        }
        const _locations = [];
        for (const location of locations) {
            _locations.push(location.value);
        }
        const data = {
            url,
            image: _imageUrl,
            page,
            locations: _locations,
        };
        await updateSlider(slider,  data);
        setLoading(false);
    };

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("files", file);
        const urls = await upload(formData);
        return urls.length > 0 ? urls[0] : '';
    }


    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Edit Slider</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="mb-3 col-sm-12 col-md-6">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} />
                            {imageUrl !== null && <a href={imageUrl} target='_blank'>Click here to download image</a>}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="url" className="form-label">Link</label>
                                <input type="text" className="form-control" id="url" name="url" value={url} placeholder="Url"
                                       onChange={(e) => setUrl(e.target.value)}/>
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="type" className="form-label">Locations</label>
                            <Select
                                name="form-field-name"
                                options={locationOptions}
                                onChange={setLocations}
                                value={locations}
                                isMulti={true} />
                        </div>

                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default EditSlider;
