import React, {useEffect, useState} from 'react';
import store from "../../store";
import Select from 'react-select';
import 'react-select-2/dist/css/react-select-2.css';
import {useSelector} from "react-redux";
import {addMember, generateRandomPassword} from "../../services/TeamService";

const AddTeamMember = () => {
    const allPermissions = useSelector((state) => state.common.permissions)
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [permissionOptions, setPermissionOptions] = useState([]);

    useEffect(() => {
        let _permissions = [];
        allPermissions.forEach((per) => {
            if (!per.deleted) {
                _permissions.push({ value: per._id, label: per.title },)
            }
        });
        setPermissionOptions(_permissions);
    }, [allPermissions])

    useEffect(() => {
        const password = generateRandomPassword(12);
        setPassword(password);
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const _permissions = [];
        permissions.forEach((per) => {
            _permissions.push(per.value);
        });
        const data = {
            name,
            email,
            password,
            permissions: _permissions,
        };
        const res = await addMember(data);
        if (res) {
            e.target.reset();
            setEmail(null);
            setName(null);
            setPassword(null);
            setPermissions([]);
        }
        setLoading(false);
    };

    return (<>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Add Team Member</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="name" name="name" placeholder="Name"
                                       onChange={(e) => setName(e.target.value)} value={name} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="email" name="email" placeholder="Email"
                                       onChange={(e) => setEmail(e.target.value)} value={email} required={true}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password <span
                                    className='text-danger'>(required)</span></label>
                                <input type="text" className="form-control" id="password" name="password" placeholder="Password"
                                       onChange={(e) => setPassword(e.target.value)} value={password} required={true}/>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-12">
                            <label htmlFor="type" className="form-label">Permissions</label>
                            <Select
                                name="form-field-name"
                                options={permissionOptions}
                                isMulti={true}
                                onChange={setPermissions} />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary me-2" disabled={loading}>{loading &&
                        <span className="spinner-border spinner-border-sm me-2" role="status"/>} Submit</button>

                </form>

            </div>
        </div>
    </>);
}

export default AddTeamMember;
